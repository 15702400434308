<template>
  <div :class="containerClass">
    <h2>Configure Skill</h2>

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <b-form-group label="Title" label-for="title" label-class="form-label">
        <b-form-input
          id="title"
          placeholder="Enter skill title"
          v-model.trim="skill.title"
          maxlength="250"
          :state="!$v.skill.title.required && $v.skill.title.$dirty ? false : null"
        />
        <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="Description" label-for="description" label-class="form-label">
        <b-form-textarea
          id="description"
          v-model.trim="skill.description"
          rows="15"
          placeholder="Enter skill description"
          :state="!$v.skill.description.required && $v.skill.description.$dirty ? false : null"
        />
        <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="Related Programs" label-for="offered_programs" label-class="form-label">
        <v-select
          id="offered_programs"
          class="form-control v-select-custom"
          label="title"
          v-model="skill.programs"
          :reduce="program => program.id"
          placeholder="Select programs related to this skill"
          :options="allPrograms"
          :loading="areProgramsLoading"
          multiple
          :class="!$v.skill.programs.required && $v.skill.programs.$dirty ? 'form-control is-invalid' : ''"
        >
          <template slot="option" slot-scope="option">
            <span>{{ option.title }}</span>
          </template>
        </v-select>
        <b-form-invalid-feedback :state="!$v.skill.programs.required && $v.skill.programs.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <b-form-group
        label="Sample Video Demonstration"
        label-for="video"
        label-class="form-label"
        class="row-align-items-center"
      >
        <!-- <b-media class="align-items-center" vertical-align="center"> -->
        <template v-if="!skill.sample_video_url">
          <b-form-file
            id="video"
            placeholder="Select skill demonstration video (max size: 50MB)"
            :browse-text="$t('generalMsgs.browse')"
            v-model="uploadedVidFile"
            @input="uploadVideo"
            :disabled="isVideoUploading"
            accept="video/mp4, video/webm"
            :state="!$v.skill.sample_video_url.required && $v.skill.sample_video_url.$dirty ? false : null"
          />
          <!-- </b-media> -->
          <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
        </template>

        <!-- </b-form-group>
      <b-form-group v-if="skill.sample_video_url" label="Promo Video" label-for="classes_video" label-class="form-label"> -->
        <div v-else>
          <div class="d-flex justify-content-end mb-3">
            <b-btn style="width: 80px" variant="danger" @click.prevent="skill.sample_video_url = ''"> Remove </b-btn>
          </div>
          <video id="video" width="100%" height="200" controls crossOrigin="anonymous">
            <source :src="skill.sample_video_url" type="video/mp4" />
          </video>
          <canvas width="400" height="225" id="canvas" style="display: none;"></canvas>
        </div>
      </b-form-group>

      <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isSaving" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>Save</span>
      </b-btn>
    </b-form>
  </div>
</template>

<script>
import Page from '@/components/Page.vue';
import { required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';
import { dataURLToBlob } from '@/common/utils';
import { uuid } from 'vue-uuid';

export default {
  extends: Page,

  data() {
    return {
      title: 'Configure Skill',

      skill: {
        title: '',
        description: '',
        sample_video_url: null,
        sample_video_thumbnail_url: null,
      },
      uploadedVidFile: null,
      allPrograms: [],

      isLoading: false,
      isSaving: false,
      areProgramsLoading: false,
      isVideoUploading: false,
      isThumbUploading: false,
    };
  },

  validations() {
    return {
      skill: {
        title: { required },
        description: { required },
        sample_video_url: { required },
        programs: { required },
        sample_video_thumbnail_url: {},
      },
    };
  },

  computed: {
    isFormLoading() {
      return this.isLoading || this.areProgramsLoading || this.isVideoUploading || this.isSaving;
    },
  },

  methods: {
    ...mapActions('school', ['getSchoolImageUploadPresignedUrl']),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    ...mapActions('program', ['getAllPrograms']),
    ...mapActions('skills', ['postSchSkillConfig', 'updateSchSkillConfig', 'getSkillConfigById']),

    async onSubmit() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.isSaving = true;

        if (this.$route.params.id) {
          await this.updateSchSkillConfig({ id: this.$route.params.id, data: this.skill });
        } else {
          await this.postSchSkillConfig(this.skill);
        }
        this.$router.push({ name: 'manage-skill-config' });
        this.makeToast({ variant: 'success', msg: 'Successfully saved!' });

        this.isSaving = false;
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields.' });
      }
    },
    async uploadVideo() {
      this.isVideoUploading = true;

      // check file size and type from this.uploadedVidFile
      if (this.uploadedVidFile.size > 50000000) {
        this.makeToast({ variant: 'danger', msg: 'File size should not exceed 50MB' });
        this.uploadedVidFile = null;
        this.isVideoUploading = false;
        return;
      }
      if (!['video/mp4', 'video/webm'].includes(this.uploadedVidFile.type)) {
        this.makeToast({ variant: 'danger', msg: 'File type should be mp4 or webm' });
        this.uploadedVidFile = null;
        this.isVideoUploading = false;
        return;
      }

      try {
        const urlResp = await this.getSchoolImageUploadPresignedUrl({
          file_name: this.uploadedVidFile.name,
          content_type: this.uploadedVidFile.type,
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.uploadedVidFile,
        });
        this.skill.sample_video_url = urlResp.upload_url.split('?')[0];
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        this.uploadedVidFile = null;
      }
      this.isVideoUploading = false;
    },

    async captureThumbnail() {
      this.isThumbUploading = true;

      const canvas = document.getElementById('canvas');
      const video = document.getElementById('video');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext('2d');
      video.currentTime = 0;

      setTimeout(async () => {
        try {
          context.drawImage(video, 0, 0, canvas.width, canvas.height);

          const capturedImage = canvas.toDataURL('image/png');

          // uploading captured image to s3 by converting it to blob
          const blob = dataURLToBlob(capturedImage);

          const urlResp = await this.getSchoolImageUploadPresignedUrl({
            file_name: `${uuid.v4()}.png`,
            content_type: 'image/png',
          });

          await this.uploadToPresignedUrl({ url: urlResp.upload_url, file: blob });
          this.skill.sample_video_thumbnail_url = urlResp.upload_url.split('?')[0];
          this.isThumbUploading = false;
        } catch (error) {
          this.isThumbUploading = false;
        }
      }, 100);
    },

    async fetchPrograms() {
      this.areProgramsLoading = true;

      const response = await this.getAllPrograms({ ordering: 'id' });
      this.allPrograms = response.data;
      this.areProgramsLoading = false;
    },

    async fetchSkillConfig() {
      this.isLoading = true;

      this.skill = (await this.getSkillConfigById(this.$route.params.id)).data;

      this.isLoading = false;
    },
  },

  async mounted() {
    if (this.$route.params.id) {
      this.fetchSkillConfig();
    }
    this.fetchPrograms();
  },
};
</script>

<style></style>
