<template>
  <div>
    <div :class="containerClass" class="d-flex justify-content-between mt-3">
      <h2>
        {{ title }}
        <i
          class="material-icons icon-16pt clickable-item"
          v-b-popover.hover.top="
            `Manage tags and associate them with students for better categorization. Consider these tags as program's classes.`
          "
          >info</i
        >
      </h2>
    </div>
    <div class="page-section">
      <div :class="containerClass">
        <page-separator :title="title" />

        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input class="w-lg-auto" placeholder="Search tags" v-model="searchTerm" @input="onSearch" />
                  <b-btn variant="flush" type="submit">
                    <md-icon>search</md-icon>
                  </b-btn>
                </form>
              </div>

              <!-- Filters and Add Btn -->
              <div class="col-lg d-flex flex-wrap justify-content-end">
                <div class="col-lg-7 px-0" :class="isTabSmallScreen ? 'mt-2 mb-2' : 'mr-2'">
                  <v-select
                    id="program"
                    class="form-control v-select-custom"
                    label="title"
                    v-model="selectedProgram"
                    :reduce="pg => pg.id"
                    placeholder="Select Program"
                    :options="allPrograms"
                    :loading="areProgramsLoading"
                    @input="fetchTags()"
                  >
                    <!-- :disabled="$route.name === 'edit-attendance'" -->
                  </v-select>
                </div>
                <b-btn @click.prevent="openTagModal()" variant="primary">Add Tag</b-btn>
              </div>
            </div>
          </div>

          <!-- Schools Table -->
          <b-table
            :fields="tableColumns"
            :items="allTags"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap"
            hover
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
          >
            <template #cell(menu)="data">
              <div class="text-center">
                <b-dropdown :boundary="'main'" no-flip variant="flush" toggle-class="text-muted" no-caret>
                  <template #button-content>
                    <i class="material-icons icon-24pt card-course__icon-favorite text-info">more_vert</i>
                    <span class="sr-only">Menu</span>
                  </template>

                  <div>
                    <b-dropdown-item @click.prevent="openTagModal(data.item)">
                      <md-icon class="text-info">edit</md-icon>
                      Edit</b-dropdown-item
                    >
                  </div>

                  <b-dropdown-item href="#" @click.prevent="confirmDeleteJob(data.item.id)">
                    <md-icon class="text-danger">delete</md-icon>
                    Delete
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalTags"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="allTags-table"
            />
          </div>
        </div>
      </div>
    </div>
    <tag-modal
      :show-modal="showTagModal"
      @close="hideTagModal"
      :index="index"
      :existingTag="tag"
      :allPrograms="allPrograms"
      :studentCount="studentCount"
      :existingProgram="program"
    />
  </div>
</template>

<script>
// import PageHeader from '@/components/Ui/PageHeader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import { debounce, get } from 'lodash';
import { mapActions } from 'vuex';

import Page from '@/components/Page.vue';
import Pagination from '../../../components/Ui/Pagination.vue';
import { DEFAULT_PAGE_SIZE } from '../../../common/constants';
import TagModal from './TagModal.vue';

export default {
  components: { PageSeparator, Pagination, TagModal },
  extends: Page,

  data() {
    return {
      title: 'Manage Tags',
      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalTags: 0,
      allTags: [],
      showTagModal: false,
      index: null,
      tag: '',
      ordering: '-cna_class_id',
      studentCount: 0,
      searchTerm: '',
      program: null,
      selectedProgram: null,
      areProgramsLoading: false,
      allPrograms: [],
      windowWidth: window.innerWidth
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Tags', active: true }
      ];
    },

    isTabSmallScreen() {
      return this.windowWidth <= 767;
    },

    tableColumns() {
      return [
        {
          key: 'menu',
          label: '',
          tdClass: 'text-left'
        },
        { key: 'cna_class_id', label: 'HE Class Id', sortable: true },
        { key: 'program.title', label: 'Program' },
        { key: 'title', label: 'Tag' },
        { key: 'tagged_students_count', label: 'Linked Students' }
      ];
    }
  },

  methods: {
    ...mapActions('attendance', ['getTags', 'deleteTag']),
    ...mapActions('program', ['getSclPrograms']),
    get,
    async fetchPrograms() {
      this.areProgramsLoading = true;
      try {
        const res = await this.getSclPrograms();
        this.allPrograms = res.data;
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.areProgramsLoading = false;
    },

    async fetchTags(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getTags({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.selectedProgram && { program__id: this.selectedProgram }),
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params
      });
      this.allTags = response.data.data.results;
      this.currentPage = pageNum;
      this.totalTags = response.data.data.count;
      this.isLoading = false;
    },
    openTagModal(item = {}) {
      this.tag = item?.title;
      this.studentCount = item?.tagged_students_count;
      this.program = item?.program;
      this.index = item?.id;
      this.showTagModal = true;
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    hideTagModal() {
      this.showTagModal = false;
      this.fetchTags(this.currentPage);
    },
    onPageChange(pageNum) {
      this.fetchTags(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchTags();
    },

    onSearch() {
      this.debouncedSearchSchools(this);
    },

    debouncedSearchSchools: debounce(vm => {
      vm.fetchTags();
    }, 500),

    async confirmDeleteJob(id) {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          `Please confirm that you want to remove the selected tag.`,
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          }
        );
        if (isConfirmed) {
          await this.deleteTag(id);
          const refreshPage = this.allTags.length > 1 ? this.currentPage : this.currentPage - 1;
          this.fetchTags(refreshPage || 1);
          this.makeToast({ variant: 'success', msg: 'Job Successfully Removed' });
        }
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    }
  },

  async mounted() {
    this.fetchTags();
    this.fetchPrograms();

    window.addEventListener('resize', this.handleResize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>
