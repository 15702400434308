<template>
  <div>
    <page-header :title="title" :breadcrumb="breadcrumb" :container-class="containerClass" />
    <div class="page-section">
      <div :class="containerClass">
        <page-separator title="" />
        <b-skeleton-wrapper :loading="isLoading">
          <template #loading>
            <div class="row">
              <div class="col-lg-2">
                <div class="d-flex justify-content-center">
                  <a
                    href="#"
                    @click.prevent
                    class="avatar avatar-xxl overlay js-overlay overlay--primary rounded-circle p-relative o-hidden mb-16pt align-self-center"
                    style="border: 1px solid #dfe2e6;id #dfe2e6;id #dfe2e6;pxl"
                  >
                    <b-skeleton-img class="avatar-img" width="160" height="100%"></b-skeleton-img>
                  </a>
                </div>

                <div class="mb-3">
                  <b-skeleton type="button" width="100%"></b-skeleton>
                </div>
              </div>

              <div class="col-lg-10 d-flex mt-3">
                <div class="flex" style="max-width: 100%">
                  <h3><b-skeleton width="100%"></b-skeleton></h3>
                  <div class="mb-0 mt-3" style="text-transform: none">
                    <b-skeleton width="100%"></b-skeleton>
                    <b-skeleton width="55%"></b-skeleton>
                    <b-skeleton width="70%"></b-skeleton>
                    <b-skeleton width="65%"></b-skeleton>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div class="row">
            <div class="col-lg-2">
              <div class="d-flex justify-content-center">
                <a
                  href="#"
                  @click.prevent
                  class="avatar avatar-xxl overlay js-overlay overlay--primary rounded-circle p-0 o-hidden d-flex justify-content-center align-items-center mb-3"
                  style="border: 1px solid #dfe2e6;id #dfe2e6;id #dfe2e6;pxl"
                >
                  <img class="avatar-img m-0" :src="user.avatar_url || DefaultAvatar" width="160" height="auto" />
                </a>
              </div>

              <div class="d-flex justify-content-center my-2">
                <b-badge
                  :class="`border border-${TESTIMONIAL_STATUSES_COLORS[getStatus]} text-${TESTIMONIAL_STATUSES_COLORS[getStatus]}`"
                  variant="none"
                  v-b-popover.hover.top
                  :title="$t(`testimonalStatusDesc.${getStatus}`)"
                  >{{ $t(`testimonalStatusChoice.${getStatus}`) }}
                </b-badge>
              </div>

              <div class="mb-3" v-if="get(this.getLoggedInUser, 'role') === USER_ROLES.SCHOOL_OWNER">
                <b-btn
                  v-if="
                    testimonial.approval_status === TESTIMONIAL_STATUSES.PENDING ||
                    testimonial.approval_status === TESTIMONIAL_STATUSES.REJECTED
                  "
                  variant="success"
                  :disabled="isFormLoading"
                  class="mt-2 w-100 mr-4 btn-normal"
                  type="submit"
                  @click.prevent="updateStatus({ approvalStatus: TESTIMONIAL_STATUSES.APPROVED, isVisible: true })"
                >
                  <i v-if="isStatusUpdating" class="fas fa-circle-notch fa-spin"></i>
                  <span v-else>Approve</span>
                </b-btn>

                <b-btn
                  v-if="testimonial.approval_status === TESTIMONIAL_STATUSES.PENDING"
                  variant="danger"
                  :disabled="isFormLoading"
                  class="mt-2 w-100 mr-4 btn-normal"
                  type="submit"
                  @click.prevent="updateStatus({ approvalStatus: TESTIMONIAL_STATUSES.REJECTED, isVisible: false })"
                >
                  <i v-if="isStatusUpdating" class="fas fa-circle-notch fa-spin"></i>
                  <span v-else>Reject</span>
                </b-btn>

                <b-btn
                  v-else-if="
                    testimonial.approval_status === TESTIMONIAL_STATUSES.APPROVED && testimonial.is_visible === false
                  "
                  variant="light"
                  :disabled="isFormLoading"
                  class="mt-2 w-100 mr-4 btn-normal"
                  type="submit"
                  @click.prevent="updateStatus({ isVisible: true })"
                >
                  <i v-if="isStatusUpdating" class="fas fa-circle-notch fa-spin"></i>
                  <span v-else><md-icon class="icon-16pt mr-1">visibility</md-icon>Show</span>
                </b-btn>

                <b-btn
                  v-else-if="
                    testimonial.approval_status === TESTIMONIAL_STATUSES.APPROVED && testimonial.is_visible === true
                  "
                  variant="light"
                  :disabled="isFormLoading"
                  class="mt-2 w-100 mr-4 btn-normal"
                  type="submit"
                  @click.prevent="updateStatus({ isVisible: false })"
                >
                  <i v-if="isStatusUpdating" class="fas fa-circle-notch fa-spin"></i>
                  <span v-else><md-icon class="icon-16pt mr-1">visibility_off</md-icon>Hide</span>
                </b-btn>
              </div>
            </div>

            <div class="col-lg-10 d-flex">
              <div class="flex" style="max-width: 100%">
                <div class="mb-0">
                  <!-- <p class="" style="font-size: 15px">
                  <strong class="mr-1">{{ user.first_name }} {{ user.last_name }}</strong>
                </p> -->
                  <h3>{{ user.first_name }} {{ user.last_name }}</h3>
                  <p class="" style="font-size: 15px">
                    <strong class="text-primary mr-1">Current Employer: </strong>
                    {{ testimonial.curr_employer || 'None' }}
                  </p>
                  <p class="" style="font-size: 15px">
                    <strong class="text-primary mr-1">Year Completed: </strong>{{ getYearCompleted(user.grad_date) }}
                  </p>
                  <p class="" style="font-size: 15px">
                    <strong class="text-primary mr-1">{{ `Why I came to ${get(getCurrentSchool, 'name')}?` }}</strong>
                    {{ testimonial.school_joining_reason }}
                  </p>
                  <p class="" style="font-size: 15px">
                    <strong class="text-primary mr-1">{{
                      `What career successes have happened with you, since completing your CNA training at ${get(
                        getCurrentSchool,
                        'name'
                      )}?`
                    }}</strong>
                    {{ testimonial.career_success_details }}
                  </p>
                  <p class="" style="font-size: 15px">
                    <strong class="text-primary mr-1">Tips to pass on to the readers:</strong>
                    {{ testimonial.readers_tips }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>
      </div>
    </div>
  </div>
</template>


<script>
import { get } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import PageHeader from '@/components/Ui/PageHeader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
//import { FmvAvatar } from 'fmv-avatar';
import moment from 'moment';
import Page from '../../../components/Page.vue';
// import UserAvatar from '../../../components/User/UserAvatar.vue';
//import UserAvatar from '../../../components/User/UserAvatar.vue';
import DefaultAvatar from '@/assets/images/logos/logo_icon.png';
import { TESTIMONIAL_STATUSES, TESTIMONIAL_STATUSES_COLORS, USER_ROLES } from '../../../common/constants';
import 'vue-swatches/dist/vue-swatches.css';
export default {
  components: {
    PageHeader,
    PageSeparator,
    // FmvAvatar,
    //  UserAvatar,
  },
  extends: Page,

  data() {
    return {
      title: 'Review Testimonial',
      DefaultAvatar,
      USER_ROLES,
      isLoading: false,
      isStatusUpdating: false,
      user: {
        first_name: '',
        last_name: '',
        avatar_url: '',
        grad_date: '',
      },
      testimonial: {
        school_joining_reason: '',
        career_success_details: '',
        readers_tips: '',
        approval_status: 'approved',
        is_visible: false,
        curr_employer: null,
      },
      TESTIMONIAL_STATUSES,
      TESTIMONIAL_STATUSES_COLORS,
    };
  },

  computed: {
    ...mapGetters('school', ['getCurrentSchool']),
    ...mapGetters('auth', ['getLoggedInUser']),
    breadcrumb() {
      return [
        { text: 'Home', to: this.routes.home },
        {
          text: 'Manage Testimonials',
          to: { name: 'manage-student-testimonial-list' },
        },
        {
          text: 'Review Testimonial',
          active: true,
        },
      ];
    },

    isFormLoading() {
      return this.isLoading || this.isStatusUpdating;
    },

    getStatus() {
      return this.testimonial.approval_status === TESTIMONIAL_STATUSES.PENDING
        ? TESTIMONIAL_STATUSES.PENDING
        : this.testimonial.approval_status === TESTIMONIAL_STATUSES.REJECTED
        ? TESTIMONIAL_STATUSES.REJECTED
        : this.testimonial.approval_status === TESTIMONIAL_STATUSES.APPROVED && this.testimonial.is_visible === true
        ? TESTIMONIAL_STATUSES.VISIBLE
        : TESTIMONIAL_STATUSES.HIDDEN;
    },
  },

  methods: {
    ...mapActions('testimonial', ['getTestimonial', 'updateTestimonialStatus']),
    get,
    getYearCompleted(gradDate) {
      const oneDate = moment(gradDate, 'YYYY-MM-DD');
      return oneDate.format('YYYY');
    },

    async updateStatus({ approvalStatus = undefined, isVisible = undefined }) {
      this.isStatusUpdating = true;
      try {
        let isConfirmed = true;
        if (approvalStatus === TESTIMONIAL_STATUSES.REJECTED) {
          isConfirmed = await this.$bvModal.msgBoxConfirm(
            'Please confirm that you want to reject the selected testimonial.',
            {
              title: 'Are you sure?',
              size: 'md',
              buttonSize: 'sm',
              okVariant: 'danger',
              okTitle: 'Yes',
              cancelTitle: 'No',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true,
            }
          );
        }

        if (isConfirmed) {
          const data = {
            ...(approvalStatus && { approval_status: approvalStatus }),
            ...(isVisible !== undefined && { is_visible: isVisible }),
          };

          await this.updateTestimonialStatus({ id: this.$route.params.id, data });
          this.testimonial = { ...this.testimonial, ...data };

          if (approvalStatus === TESTIMONIAL_STATUSES.APPROVED) {
            this.makeToast({ variant: 'success', msg: 'Testimonial has been approved.' });
          } else if (approvalStatus === TESTIMONIAL_STATUSES.REJECTED) {
            this.makeToast({ variant: 'success', msg: 'Testimonial has been rejected.' });
          } else if (isVisible) {
            this.makeToast({ variant: 'success', msg: 'Testimonial is now visible.' });
          } else {
            this.makeToast({ variant: 'success', msg: 'Testimonial hidden.' });
          }
        }
      } catch ($e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isStatusUpdating = false;
    },

    async fetchTestimonial() {
      this.isLoading = true;
      try {
        const res = await this.getTestimonial(this.$route.params.id);
        this.user.first_name = res.data.created_by.first_name;
        this.user.last_name = res.data.created_by.last_name;
        this.user.avatar_url = res.data.created_by.avatar_url;
        this.user.grad_date = res.data.created_by.grad_date;
        this.testimonial.school_joining_reason = res.data.school_joining_reason;
        this.testimonial.career_success_details = res.data.career_success_details;
        this.testimonial.approval_status = res.data.approval_status;
        this.testimonial.readers_tips = res.data.readers_tips;
        this.testimonial.is_visible = res.data.is_visible;
        this.testimonial.curr_employer = res.data.curr_employer;
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },
  },

  async mounted() {
    this.fetchTestimonial();
  },
};
</script>
