<template>
  <div>
    <div :class="containerClass" class="d-flex justify-content-between mt-3">
      <h2>
        View Attendance
        <i
          class="material-icons icon-16pt clickable-item"
          v-b-popover.hover.top="`View attendance of each program and associated tag.`"
          >info</i
        >
      </h2>
    </div>

    <div class="page-section">
      <div :class="containerClass">
        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input
                    class="w-lg-auto"
                    placeholder="Search Students"
                    v-model="searchTerm"
                    @input="onSearch"
                  />
                  <b-btn variant="flush" type="submit">
                    <md-icon>search</md-icon>
                  </b-btn>
                </form>
              </div>

              <div
                v-if="attendanceView === 'view' && !isTabSmallScreen"
                class="col-lg d-flex flex-wrap justify-content-end"
              >
                <b-btn
                  @click.prevent="exportTable"
                  class="btn-normal"
                  variant="primary"
                  :disabled="isLoading || !students.length"
                >
                  Export Table</b-btn
                >
              </div>

              <div class="row mx-auto mb-2 w-100 mt-2">
                <div class="col-md-6 col-lg-12 mb-2">
                  <date-picker
                    v-model="date"
                    format="DD MMMM, YYYY"
                    valueType="date"
                    id="date"
                    lang="en"
                    range
                    placeholder="Start Date Range"
                    @input="dateChange"
                    class="form-control datepicker-filter-custom w-100"
                  ></date-picker>
                </div>
                <div class="col-md-6 mb-2">
                  <v-select
                    id="state"
                    class="form-control v-select-custom"
                    label="title"
                    v-model="program"
                    :reduce="pg => pg.id"
                    placeholder="Select Program"
                    :options="allPrograms"
                    :loading="areProgramsLoading"
                    @input="fetchTags"
                  >
                  </v-select>
                </div>
                <div class="col-md-6 mb-2">
                  <v-select
                    id="tag"
                    class="form-control v-select-custom"
                    label="title"
                    v-model="tag_id"
                    :value="tag_id"
                    :reduce="sc => sc.id"
                    placeholder="Select Program Tag"
                    :options="allTags"
                    :loading="areTagsLoading && !allTags.length"
                    @search="fetchOptions"
                    :disabled="(areTagsLoading && !allTags.length) || !program"
                  >
                    <template #list-footer>
                      <li
                        v-observe-visibility="visibilityChanged"
                        v-show="allTags.length && allTags.length < totalTags"
                        class="loader"
                      ></li>
                    </template>
                    <template slot="option" slot-scope="option">
                      <span :id="`tag-${option.id}`">
                        {{ option.title }}
                      </span>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      {{ option.title }}
                    </template>
                  </v-select>
                </div>
                <div class="col-md-6" v-if="!isTabSmallScreen">
                  <b-form-radio-group
                    v-model="attendanceView"
                    :options="VIEW_OPTIONS"
                    buttons
                    button-variant="outline-dark"
                    style="width: 350px"
                  ></b-form-radio-group>
                </div>
                <div
                  class="col-lg d-flex align-items-center justify-content-end"
                  :class="{ 'flex-wrap': !isTabSmallScreen }"
                >
                  <!-- <div
                    
                    class="col-lg d-flex flex-wrap justify-content-end"
                  > -->
                  <b-btn
                    v-if="attendanceView === 'view' && isTabSmallScreen && students.length"
                    @click.prevent="exportTable"
                    class="btn-normal w-100 mr-1"
                    variant="primary"
                    :disabled="isLoading || !students.length"
                  >
                    Export Table</b-btn
                  >
                  <!-- </div> -->
                  <b-btn
                    @click.prevent="attendanceView === 'summary' ? fetchSummary() : fetchStudents()"
                    class="btn-normal"
                    :class="{ 'w-100 ml-1': isTabSmallScreen }"
                    variant="primary"
                    :disabled="isLoading || !tag_id || !program || !date"
                    >Fetch Students</b-btn
                  >
                </div>
                <div class="col-md-6 mt-2" v-if="isTabSmallScreen">
                  <b-form-radio-group
                    v-model="attendanceView"
                    :options="VIEW_OPTIONS"
                    buttons
                    button-variant="outline-dark"
                    style="width: 100%"
                  ></b-form-radio-group>
                </div>
              </div>
            </div>
          </div>

          <b-table
            :fields="isLoading ? [] : attendanceView === 'summary' ? summaryTableColumns : tableColumns"
            :items="isLoading ? [] : attendanceView === 'summary' ? summary : students"
            v-if="students.length || summary.length"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap"
            hover
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
            @row-clicked="rowClicked"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>

            <template #cell(avatar)="data">
              <div class="text-center">
                <user-avatar slot="aside" size="md" :fileUrl="data.item.student.image_url"> </user-avatar>
              </div>
            </template>

            <template #cell(name)="data"
              ><strong> {{ data.item.student.full_name }}</strong></template
            >

            <template v-for="std in attendanceColumns" v-slot:[`cell(${std.key})`]="data">
              <div class="d-flex align-items-center" :key="std.key">
                <b-badge pill :variant="data.item.attendances[std.key].status === 'P' ? 'success' : 'danger'">
                  {{ data.item.attendances[std.key].status === 'P' ? 'Present' : 'Absent' }}
                  <md-icon
                    v-if="data.item.attendances[std.key].comments"
                    style="font-size: 12px"
                    class="ml-1 mr-0"
                    :id="`info-popover-${data.item.id}-${std.key}`"
                    >info</md-icon
                  >
                  <info-popover
                    :info="data.item.attendances[std.key].comments"
                    :target-id="`info-popover-${data.item.id}-${std.key}`"
                  />
                </b-badge>
              </div>
            </template>

            <!-- <template #cell(attendance)="data">
              <div>{{ data.item.present_students }}/{{ data.item.total_students }}</div>
            </template> -->
          </b-table>

          <div v-else-if="fetched" class="text-center p-3">
            <i> No students were found </i>
          </div>
        </div>
      </div>
    </div>
    <b-modal ref="myModal" title="Downloading Attendance" centered hide-footer @hide="hideLoaderModal">
      <span v-if="isExporting">
        <i
          style="font-size: 3rem"
          class="d-flex justify-content-center fas fa-circle-notch fa-spin text-primary mb-2"
        ></i>
        <p class="text-70 text-center">Your file is being downloaded...</p>
      </span>
      <span v-else-if="fileUrl">
        <i
          class="d-flex justify-content-center fas fa-check-circle fa-lg text-success mb-4 mt-4"
          style="font-size: 3rem"
        />
        <p class="text-70 text-center">Your file has been downloaded.</p>
      </span>
      <span v-else-if="!fileUrl">
        <i
          class="d-flex justify-content-center fas fa-times-circle fa-lg text-danger mb-4 mt-4"
          style="font-size: 3rem"
        />
        <p class="text-70 text-center">Download Failed!</p>
      </span>
    </b-modal>
  </div>
</template>

<script>
import { debounce, get, keyBy } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import Page from '@/components/Page.vue';
import { DEFAULT_PAGE_SIZE } from '../../../common/constants';
import InfoPopover from './InfoPopover.vue';
import { formatDateSimple, formatToAPIDate, formatFullDate } from '../../../common/utils';
import UserAvatar from '../../../components/User/UserAvatar.vue';
import DatePicker from 'vue2-datepicker';
import Vue from 'vue';
import { ObserveVisibility } from 'vue-observe-visibility';

Vue.directive('observe-visibility', ObserveVisibility);

export default {
  components: {
    UserAvatar,
    DatePicker,
    InfoPopover
  },
  extends: Page,

  data() {
    return {
      title: 'View Attendance',
      date: null,
      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalStudents: 0,
      students: [],
      searchTerm: null,
      program: null,
      tag_id: null,
      tagFilter: null,
      allPrograms: [],
      areProgramsLoading: false,
      windowWidth: window.innerWidth,
      allTags: [],
      areTagsLoading: false,
      attendanceColumns: [],
      fileUrl: null,
      isExporting: false,
      fetched: false,
      summary: [],
      attendanceView: 'summary',
      VIEW_OPTIONS: [
        { value: 'summary', text: 'View Summary' },
        { value: 'view', text: 'View Report' }
      ],
      limit: 15,
      offset: 0,
      totalTags: 0,
      search: ''
    };
  },

  watch: {
    date: {
      handler() {
        this.reset();
      }
    },

    program: {
      handler() {
        this.allTags = [];
        this.offset = 0;
        this.tag_id = null;
        this.search = '';
        this.reset();
      }
    },

    tag_id: {
      handler() {
        this.reset();
      }
    },

    attendanceView: {
      handler(value) {
        if (this.fetched) {
          if (value === 'summary') {
            this.fetchSummary();
          } else {
            this.fetchStudents();
          }
        }
      }
    }
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    tableColumns() {
      return [
        { key: 'avatar', label: '' },
        { key: 'name', label: 'Name' }
      ].concat(this.attendanceColumns);
    },
    summaryTableColumns() {
      return [
        { key: 'date', label: 'Date', formatter: value => formatFullDate(value), tdClass: 'clickable-item' },
        { key: 'present_students', label: 'Present Students', tdClass: 'clickable-item' },
        { key: 'total_students', label: 'Total Students', tdClass: 'clickable-item' }
      ];
    },
    isTabSmallScreen() {
      return this.windowWidth <= 767;
    }
  },

  methods: {
    get,
    ...mapActions('attendance', ['viewAttendance', 'getTags', 'getSummary', 'exportAttendanceTable']),
    ...mapActions('program', ['getSclPrograms']),
    ...mapActions('fileDownload', ['downloadFile']),

    reset() {
      this.summary = [];
      this.students = [];
      this.fetched = false;
    },

    visibilityChanged(reached) {
      if (reached) {
        this.offset += 15;
        this.fetchTags();
      }
    },

    async fetchTags() {
      this.tag_id = null;
      if (!this.search) this.areTagsLoading = true;
      const response = await this.getTags({
        ...(this.search && { search: this.search }),
        ...(this.tagFilter && { id: this.tagFilter }),

        program__id: this.program,
        limit: this.limit,
        offset: this.offset
      });

      this.allTags = this.allTags.concat(response.data.data.results);
      this.totalTags = response.data.data.count;
      this.areTagsLoading = false;

      if (this.allTags.length > 15) {
        setTimeout(() => {
          const el = document.getElementById(`tag-${this.allTags.slice(-15)[0].id}`);

          if (el) {
            el.scrollIntoView({ behavior: 'instant', block: 'nearest' });
          }
        }, 100);
      }
    },

    debouncedSearchTag: debounce(async (loading, search, vm) => {
      vm.search = search;
      const response = await vm.getTags({
        search,
        program__id: vm.program,
        limit: vm.limit,
        offset: vm.offset
      });
      loading(false);

      vm.allTags = [];
      vm.totalTags = 0;
      vm.allTags = vm.allTags.concat(response.data.data.results);
      vm.totalTags = response.data.data.count;
    }, 500),

    async fetchOptions(search, loading) {
      if (!this.tag_id) {
        this.offset = 0;
        this.allTags = [];
        this.search = search;
        loading(true);
        this.debouncedSearchTag(loading, search, this);
      }
    },
    clearFilters() {
      this.program = null;
      this.tag_id = null;
    },
    dateChange(value) {
      if (value[0]) {
        this.date = value;
      } else {
        this.date = null;
      }
    },
    async fetchPrograms() {
      this.areProgramsLoading = true;
      try {
        const res = await this.getSclPrograms();
        this.allPrograms = res.data;
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.areProgramsLoading = false;
    },
    async fetchStudents() {
      if (!this.tag_id || !this.program || !this.date) return;
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      try {
        const data = {
          program: this.program,
          from_date: formatToAPIDate(this.date[0]),
          to_date: formatToAPIDate(this.date[1]),
          tag: this.tag_id
        };

        const response = await this.viewAttendance({
          data,
          ...(this.searchTerm && { params: { search: this.searchTerm } })
        });

        this.attendanceColumns = response.data.data.att_marked_on.map(attendance => {
          return {
            key: attendance,
            label: formatDateSimple(attendance)
          };
        });
        this.students = response.data.data.data.map(std => ({
          ...std,
          attendances: keyBy(std.attendances, 'date')
        }));

        this.fetched = true;
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: 'Maximum date range of 3 months allowed.' });
      }
      this.isLoading = false;
    },

    async fetchSummary() {
      if (!this.tag_id || !this.program || !this.date) return;
      this.isLoading = true;

      const response = await this.getSummary({
        program__id: this.program,
        from_date: formatToAPIDate(this.date[0]),
        to_date: formatToAPIDate(this.date[1]),
        tag__id: this.tag_id,
        ...(this.searchTerm && { search: this.searchTerm })
      });

      this.summary = response.data;
      this.isLoading = false;

      this.fetched = true;
    },

    rowClicked(row) {
      if (this.attendanceView === 'summary') {
        this.$router.push({
          name: 'edit-attendance',
          params: { date: row.date, program: row.program, tag: row.tag_id, backTo: true }
        });
      }
    },

    async exportTable() {
      this.isExporting = true;
      this.openModal();
      try {
        const data = {
          program: this.program,
          from_date: formatToAPIDate(this.date[0]),
          to_date: formatToAPIDate(this.date[1]),
          tag: this.tag_id
        };

        const res = await this.exportAttendanceTable(data);

        this.fileUrl = res.data.file_url;
        this.downloadGivenFile(this.fileUrl);
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isExporting = false;
    },

    openModal() {
      this.$refs.myModal.show();
    },
    hideLoaderModal() {
      this.$refs.myModal.hide();
    },

    async downloadGivenFile(url) {
      try {
        await this.downloadFile({ fileUrl: url, removeTimestamp: true });
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchStudents();
    },

    onSearch() {
      this.debouncedSearchStudentReqDocs(this);
    },

    debouncedSearchStudentReqDocs: debounce(vm => {
      vm.fetchStudents();
    }, 500),

    handleResize() {
      this.windowWidth = window.innerWidth;
    }
  },

  async mounted() {
    await this.fetchPrograms();

    let today = new Date();
    let firstDay = new Date(today.getFullYear(), today.getMonth(), 1);

    this.date = [firstDay, today];

    if (this.$route.params.program && this.$route.params.tag) {
      this.program = this.$route.params.program;

      this.tagFilter = this.$route.params.tag;
      await this.fetchTags();
      this.tag_id = this.$route.params.tag;

      this.attendanceView === 'summary' ? this.fetchSummary() : this.fetchStudents();
    }

    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>

<style scoped lang="scss">
.loader {
  text-align: center;
  color: #bbbbbb;
  margin-inline: auto;
}
</style>
