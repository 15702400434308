<template>
  <div :class="containerClass">
    <page-header
      title="Document Configuration"
      :container-class="null"
      class="mb-16pt"
      infoText="Configure document-related options."
    />
    <div>
      <b-alert show variant="primary">Click the document below to manage details</b-alert>
    </div>
    <page-separator title="Records" />
    <b-form class="col-md-12 px-0 page-section pt-0">
      <b-skeleton-wrapper :loading="isRecordLoading">
        <template #loading>
          <div class="row card-group-row mb-lg-12pt home-card">
            <div class="col-lg-6 col-md-6 col-sm-12 clickable-item mb-3" v-for="item in [1, 2]" :key="item">
              <div class="card card--elevated p-relative">
                <div class="mt-4 mb-4 ml-3 d-flex justify-content-start">
                  <b-skeleton width="55%"></b-skeleton>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div class="row card-group-row mb-lg-12pt home-card">
          <div v-for="record in records" :key="record.id" class="col-lg-6 col-md-6 col-sm-12 clickable-item mb-3">
            <div class="card card--elevated p-relative h-100" @click.prevent="openDocConfigModal(record, 'record')">
              <div class="mt-4 mb-0 d-flex justify-content-start">
                <md-icon class="mr-2 ml-2">insert_drive_file</md-icon>{{ record.title }}
              </div>
            </div>
          </div>
        </div>
      </b-skeleton-wrapper>
      <page-separator title="Credentials" />
      <b-skeleton-wrapper :loading="isCredentialLoading">
        <template #loading>
          <div class="row card-group-row mb-lg-12pt home-card">
            <div class="col-lg-6 col-md-6 col-sm-12 clickable-item mb-3" v-for="item in [1, 2]" :key="item">
              <div class="card card--elevated p-relative">
                <div class="mt-4 mb-4 ml-3 d-flex justify-content-start">
                  <b-skeleton width="55%"></b-skeleton>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div class="row card-group-row mb-lg-12pt home-card">
          <div
            v-for="credential in credentials"
            :key="credential.id"
            class="col-lg-6 col-md-6 col-sm-12 clickable-item mb-3"
          >
            <div class="card card--elevated p-relative h-100" @click.prevent="openDocConfigModal(credential, 'cred')">
              <div class="mt-4 mb-0 d-flex justify-content-start">
                <md-icon class="mr-2 ml-2">insert_drive_file</md-icon>{{ credential.title }}
              </div>
            </div>
          </div>
        </div>
      </b-skeleton-wrapper>
    </b-form>

    <doc-config-modal
      :show-modal="showDocConfigModal"
      @closeUpdate="hideUpdateDocConfigModal"
      @close="hideDocConfigModal"
      :index="index"
      :docTitle="docTitle"
      :isDocDateReq="isDocDateReq"
      :program-config="programConfig"
      :existingQuestions="existingQuestions"
    />
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import Page from '@/components/Page.vue';
import { PII_TYPE_CONFIG_OPTIONS } from '@/common/constants';
import { mapActions, mapGetters } from 'vuex';
// import EditLabel from './EditLabel.vue';
import DocConfigModal from './DocConfigModal.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';

export default {
  name: 'ConfigurePiisPage',
  extends: Page,
  components: {
    // EditLabel,
    DocConfigModal,
    PageHeader,
    PageSeparator,
  },
  data() {
    return {
      title: 'Document Cofiguration',
      records: [],
      recordsKeys: [
        'state_id',
        'social_sec_card',
        'green_card',
        'passport',
        'birth_cert',
        'immunization',
        'tb_test',
        'covid_vacc',
        'vehicle_reg',
        'background_check',
        'student_review',
        'rec_misc_doc_1',
        'rec_misc_doc_2',
        'rec_misc_doc_3',
        'rec_misc_doc_4',
        'rec_misc_doc_5',
        'rec_misc_doc_6',
        'rec_misc_doc_7',
      ],
      credentialsKeys: [
        'cpr',
        'bls',
        'first_aid',
        'completion_cert',
        'school_waiver',
        'ged',
        'cred_misc_doc_1',
        'cred_misc_doc_2',
        'cred_misc_doc_3',
        'cred_misc_doc_4',
        'cred_misc_doc_5',
        'cred_misc_doc_6',
        'cred_misc_doc_7',
      ],
      credentials: [],
      typeOptions: PII_TYPE_CONFIG_OPTIONS,
      isLoading: false,

      showDocConfigModal: false,
      index: null,
      docTitle: '',
      isDocDateReq: false,
      existingQuestions: [],
      programConfig: [],
      type: null,
      isRecordLoading: false,
      isCredentialLoading: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    isFormLoading() {
      return this.isLoading || this.isConfigPiiLoading;
    },
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Configure Student Documents', active: true },
      ];
    },
  },
  methods: {
    ...mapActions('school', ['getSchoolConfigPiis', 'updateSclConfigPiis', 'updateSclConfigPiisLabel']),

    async fetchSclConfigPiis() {
      this.isRecordLoading = this.isCredentialLoading = true;
      try {
        const res = await this.getSchoolConfigPiis({
          ordering: 'title',
        });

        this.records = res.data.filter((record) => this.recordsKeys.includes(record.type));
        this.credentials = res.data.filter((record) => this.credentialsKeys.includes(record.type));
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }

      this.isRecordLoading = this.isCredentialLoading = false;
    },
    openDocConfigModal(doc, type) {
      this.docTitle = doc.title;
      this.index = doc.id;
      this.existingQuestions = doc.custom_questions;
      this.type = type;
      this.programConfig = doc.config_programs;
      this.isDocDateReq = doc.req_expiry_date;
      this.showDocConfigModal = true;
    },

    hideUpdateDocConfigModal() {
      this.showDocConfigModal = false;
      this.fetchSclConfigPiis();
    },
    hideDocConfigModal() {
      this.showDocConfigModal = false;
    },
  },

  async mounted() {
    this.fetchSclConfigPiis();
  },
};
</script>

<style scoped>
</style>
