<template>
  <b-modal
    ref="tagsModal"
    hide-footer
    :title="existingTag ? 'Update Tag' : 'Add Tag'"
    centered
    lazy
    @hide="hideModal"
    size="md"
  >
    <div class="mb-2">
      <b-alert v-if="!!studentCount" variant="primary" show class="d-flex flex-wrap">
        <div class="mr-8pt">
          <i style="font-size: 18px" class="material-icons">info</i>
        </div>
        <div class="flex" style="min-width: 180px">
          <small class="text-black-100">
            Program category cannot be changed when there are students associated with a tag.
          </small>
        </div>
      </b-alert>

      <v-select
        id="state"
        class="form-control v-select-custom"
        label="title"
        v-model="program"
        :reduce="pg => pg"
        placeholder="Select Program"
        :options="allPrograms"
        :disabled="!!studentCount"
        :loading="areProgramsLoading"
        :class="!$v.program.required && $v.program.$dirty ? 'form-control is-invalid' : ''"
      >
      </v-select>
      <b-form-invalid-feedback :state="!$v.program.required && $v.program.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </div>
    <b-form-input maxlength="150" id="tag" placeholder="Enter Tag Title" v-model="tag" required />

    <div class="d-flex justify-content-end mt-2">
      <b-btn
        variant="primary"
        class="ml-2"
        style="width: 70px"
        @click.prevent="submitData"
        href="#"
        data-toggle="modal"
        :disabled="isLoading"
      >
        <span> {{ existingTag ? 'Update' : 'Add' }}</span></b-btn
      >
    </div>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'TagModal',

  props: {
    showModal: { type: Boolean, default: false },
    index: { type: Number, default: null },
    existingTag: { type: String, default: () => '' },
    existingProgram: { type: Object, default: () => null },
    studentCount: { type: Number, default: () => 0 },
    allPrograms: { type: Array, default: () => [] }
  },

  data() {
    return {
      tag: '',
      // allPrograms: [],
      program: null,
      isLoading: false,
      areProgramsLoading: false
    };
  },

  validations() {
    return {
      program: { required }
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser'])
  },

  methods: {
    ...mapActions('attendance', ['postTag', 'updateTag']),
    ...mapActions('program', ['getSclPrograms']),
    async fetchPrograms() {
      this.areProgramsLoading = true;
      try {
        const res = await this.getSclPrograms();
        this.allPrograms = res.data;
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.areProgramsLoading = false;
    },

    async submitData() {
      this.isLoading = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const data = {
          title: this.tag,
          school: this.getLoggedInUser.linked_entity.id,
          program: this.program.id
        };
        try {
          if (this.existingTag) {
            await this.updateTag({
              id: this.index,
              data
            });
          } else {
            await this.postTag(data);
          }
          this.$emit('update');
          this.tag = '';
          this.$refs.tagsModal.hide();
          this.makeToast({ variant: 'success', msg: 'Saved tag successfully!' });
        } catch (err) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }
      this.isLoading = false;
    },

    async hideModal() {
      this.$emit('close');
      this.tag = '';
    },

    hideModalManual() {
      this.$refs.tagsModal.hide();
      this.hideModal();
    }
  },
  watch: {
    showModal(value) {
      if (value) {
        // this.fetchPrograms();

        this.tag = this.existingTag;
        this.program = this.existingProgram;

        this.$refs.tagsModal.show();
      }
    }
  },
  mounted() {}
};
</script>

<style></style>
