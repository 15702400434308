<template>
  <div>
    <div :class="containerClass" class="mt-3">
      <div
        v-if="$route.name === 'edit-attendance'"
        class="clickable-item mb-2"
        @click.prevent="goBack()"
        style="text-decoration: underline"
      >
        <span class="material-icons icon-18pt text-80">navigate_before</span>
      </div>
      <h2>
        {{ `${$route.name === 'mark-attendance' ? 'Mark' : 'Edit'} Attendance` }}
        <i
          class="material-icons icon-16pt clickable-item"
          v-b-popover.hover.top="
            `Mark the attendance of your students, ensuring that their academic progress is recorded with precision.`
          "
          >info</i
        >
      </h2>
    </div>

    <div class="page-section">
      <div :class="containerClass">
        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <div v-if="isEditable" class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input
                    class="w-lg-auto"
                    placeholder="Search Students"
                    v-model="searchTerm"
                    @input="onSearch"
                  />
                  <b-btn variant="flush" type="submit">
                    <md-icon>search</md-icon>
                  </b-btn>
                </form>
              </div>

              <!-- <div v-if="isEditable" class="col-lg d-flex flex-wrap justify-content-end">
                <b-btn class="btn-normal" variant="primary" :disabled="isLoading"> Export Table</b-btn>
              </div> -->

              <div class="row mx-auto mb-2 w-100 mt-2">
                <div class="col-md-6 mb-2">
                  <v-select
                    id="state"
                    class="form-control v-select-custom"
                    label="title"
                    v-model="program"
                    :reduce="pg => pg.id"
                    placeholder="Select Program"
                    :options="allPrograms"
                    :disabled="$route.name === 'edit-attendance'"
                    :loading="areProgramsLoading"
                  >
                  </v-select>
                </div>
                <div class="col-md-6 mb-2">
                  <v-select
                    id="tag"
                    class="form-control v-select-custom"
                    label="title"
                    v-model="tag_id"
                    :value="tag_id"
                    :reduce="sc => sc.id"
                    placeholder="Select Program Tag"
                    :options="allTags"
                    :loading="areTagsLoading && !allTags.length"
                    @search="fetchOptions"
                    :disabled="$route.name === 'edit-attendance' || (areTagsLoading && !allTags.length) || !program"
                  >
                    <template #list-footer>
                      <li
                        v-observe-visibility="visibilityChanged"
                        v-show="allTags.length && allTags.length < totalTags"
                        class="loader"
                      ></li>
                    </template>
                    <template slot="option" slot-scope="option">
                      <span :id="`tag-${option.id}`">
                        {{ option.title }}
                      </span>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      {{ option.title }}
                    </template>
                  </v-select>
                </div>
                <div class="col-md-6 col-lg-12 mb-2">
                  <date-picker
                    v-model="date"
                    format="DD MMMM, YYYY"
                    valueType="date"
                    id="date"
                    lang="en"
                    placeholder="Select Date"
                    @input="dateChange"
                    class="form-control datepicker-filter-custom w-100"
                    :disabled="$route.name === 'edit-attendance'"
                  ></date-picker>
                </div>
                <div v-if="students.length" class="d-flex align-items-center my-2 ml-3">
                  <span class="mr-2"> Mark All As: </span>
                  <b-form-radio-group
                    :key="key"
                    :options="[
                      { value: 'P', text: 'Present' },
                      { value: 'A', text: 'Absent' }
                    ]"
                    class="text-center"
                    v-model="markAll"
                  >
                  </b-form-radio-group>
                </div>
                <div v-if="$route.name === 'mark-attendance'" class="col-lg d-flex flex-wrap justify-content-end">
                  <b-btn
                    @click.prevent="fetchStudents()"
                    class="btn-normal"
                    variant="primary"
                    :disabled="isLoading || !tag_id || !program || !date"
                    >Fetch Students</b-btn
                  >
                </div>
              </div>
            </div>
            <b-alert
              v-if="isEditable && $route.name === 'mark-attendance'"
              variant="primary"
              show
              class="d-flex flex-wrap"
            >
              <div class="mr-8pt">
                <i class="material-icons">info</i>
              </div>
              <div class="flex" style="min-width: 180px">
                <small class="text-black-100">
                  Attendance against this date for this Program Tag already exits, You can still edit it or export it
                  from

                  <router-link :to="{ name: 'view-attendance' }"> here</router-link>.
                </small>
              </div>
            </b-alert>
          </div>

          <!-- Students Table -->
          <!-- <b-skeleton-wrapper :loading="isLoading">
            <template #loading>
              <b-skeleton-table :rows="5" :columns="4" :table-props="{ hover: true }"></b-skeleton-table>
            </template> -->
          <b-table
            :fields="tableColumns"
            :items="students"
            v-if="students.length"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap"
            hover
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>

            <template #cell(avatar)="data">
              <div class="text-center">
                <user-avatar slot="aside" size="md" :fileUrl="data.item.student.image_url"> </user-avatar>
              </div>
            </template>

            <template #cell(name)="data"
              ><strong> {{ data.item.student.full_name }}</strong></template
            >

            <template #cell(present)="data">
              <b-radio-group class="text-center" v-model="data.item.status">
                <b-radio
                  value="P"
                  :state="
                    !$v.students.$each.$iter[data.index].status.required &&
                    $v.students.$each.$iter[data.index].status.$dirty
                      ? false
                      : null
                  "
                ></b-radio>
              </b-radio-group>
            </template>

            <template #cell(absent)="data">
              <b-radio-group class="text-center" v-model="data.item.status">
                <b-radio
                  value="A"
                  :state="
                    !$v.students.$each.$iter[data.index].status.required &&
                    $v.students.$each.$iter[data.index].status.$dirty
                      ? false
                      : null
                  "
                ></b-radio>
              </b-radio-group>
            </template>

            <template #cell(comments)="data">
              <b-textarea v-model="data.item.comments" maxlength="250"> </b-textarea>
            </template>
          </b-table>

          <div v-else-if="!reset && !students.length" class="text-center p-3">
            <i> No students were found </i>
          </div>

          <div v-if="students.length" class="card-footer">
            <div class="d-flex align-items-center">
              <div class="ml-auto">
                <b-btn @click.prevent="save" class="btn-normal" variant="primary" :disabled="isLoading">Save</b-btn>
                <b-btn
                  @click.prevent="confirmDelete"
                  v-if="isEditable"
                  class="btn-normal ml-2"
                  variant="danger"
                  :disabled="isLoading"
                  >Delete</b-btn
                >
              </div>
            </div>
          </div>
          <!-- </b-skeleton-wrapper> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce, get, differenceBy, pull } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import Page from '@/components/Page.vue';
import MdIcon from '../../../components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE } from '../../../common/constants';
import { formatToAPIDate } from '../../../common/utils';
import UserAvatar from '../../../components/User/UserAvatar.vue';
import DatePicker from 'vue2-datepicker';
import { required } from 'vuelidate/lib/validators';
import Vue from 'vue';
import { ObserveVisibility } from 'vue-observe-visibility';

Vue.directive('observe-visibility', ObserveVisibility);

export default {
  components: {
    MdIcon,
    UserAvatar,
    DatePicker
  },
  extends: Page,

  data() {
    return {
      title: 'Mark Attendance',
      date: null,
      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalStudents: 0,
      students: [],
      searchTerm: null,
      program: null,
      tag_id: null,
      tagFilter: null,
      allPrograms: [],
      areProgramsLoading: false,
      windowWidth: window.innerWidth,
      allTags: [],
      areTagsLoading: false,
      markAll: null,
      key: 0,
      isEditable: false,
      reset: true,
      watchTracker: [],
      updatedStudents: [],
      limit: 15,
      offset: 0,
      totalTags: 0,
      search: ''
    };
  },

  watch: {
    students() {
      return this.updateWatchers();
    },

    markAll: {
      handler() {
        if (this.markAll !== null) {
          this.students.map(student => (student.status = this.markAll));
        }
      }
    },

    date: {
      handler() {
        this.resetData();
      }
    },

    program: {
      handler(val) {
        this.allTags = [];
        this.offset = 0;
        if (this.$route.name !== 'edit-attendance') {
          this.tag_id = null;
        }
        this.resetData();
        if (val) {
          this.search = '';
          this.fetchTags();
        }
      }
    },

    tag_id: {
      handler() {
        this.resetData();
      }
    },

    '$route.name': {
      handler(val) {
        if (val === 'mark-attendance') {
          this.students = [];
          this.program = null;
          this.tag_id = null;
        }
      }
    }
  },

  validations() {
    return {
      students: {
        $each: {
          status: { required }
        }
      }
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: this.$t('studentMsgs.students'), active: true }
      ];
    },
    isTabSmallScreen() {
      return this.windowWidth <= 767;
    },
    tableColumns() {
      return [
        { key: 'avatar', label: '' },
        { key: 'name', label: 'Name' },
        { key: 'present', label: 'Present', thClass: 'text-center' },
        { key: 'absent', label: 'Absent', thClass: 'text-center' },
        { key: 'comments', label: 'Comments', tdClass: 'col-md-6', thClass: 'text-center' }
      ];
    }
  },

  methods: {
    get,
    ...mapActions('attendance', ['getStudents', 'markAttendance', 'updateAttendance', 'deleteAttendance', 'getTags']),
    ...mapActions('program', ['getSclPrograms']),

    goBack() {
      this.$router.go(-1);
    },

    resetData() {
      this.students = [];
      this.updatedStudents = [];
      this.markAll = null;
      this.isEditable = false;
      this.reset = true;
    },

    visibilityChanged(reached) {
      if (reached) {
        this.offset += 15;
        this.fetchTags();
      }
    },

    async fetchTags() {
      if (this.$route.name !== 'edit-attendance') {
        this.tag_id = null;
      }
      if (!this.search) this.areTagsLoading = true;
      const response = await this.getTags({
        ...(this.search && { search: this.search }),
        ...(this.tagFilter && { id: this.tagFilter }),

        program__id: this.program,
        limit: this.limit,
        offset: this.offset
      });

      this.allTags = this.allTags.concat(response.data.data.results);
      this.totalTags = response.data.data.count;
      this.areTagsLoading = false;

      if (this.allTags.length > 15) {
        setTimeout(() => {
          const el = document.getElementById(`tag-${this.allTags.slice(-15)[0].id}`);

          if (el) {
            el.scrollIntoView({ behavior: 'instant', block: 'nearest' });
          }
        }, 100);
      }
    },

    debouncedSearchTag: debounce(async (loading, search, vm) => {
      vm.search = search;
      const response = await vm.getTags({
        search,
        program__id: vm.program,
        limit: vm.limit,
        offset: vm.offset
      });
      loading(false);

      vm.allTags = [];
      vm.totalTags = 0;
      vm.allTags = vm.allTags.concat(response.data.data.results);
      vm.totalTags = response.data.data.count;
    }, 500),

    async fetchOptions(search, loading) {
      if (!this.tag_id) {
        this.offset = 0;
        this.allTags = [];
        this.search = search;
        loading(true);
        this.debouncedSearchTag(loading, search, this);
      }
    },
    dateChange(value) {
      if (value) {
        this.date = value;
      } else {
        this.date = null;
      }
    },
    async fetchPrograms() {
      this.areProgramsLoading = true;
      try {
        const res = await this.getSclPrograms();
        this.allPrograms = res.data;
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.areProgramsLoading = false;
    },
    async fetchStudents() {
      this.resetData();
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const data = {
        program: this.program,
        date: formatToAPIDate(this.date),
        tag: this.tag_id
      };

      const response = await this.getStudents({
        data,
        ...(this.searchTerm && { params: { search: this.searchTerm } })
      });

      this.reset = false;
      this.isEditable = response.data.data.attendance_exists;

      if (!this.isEditable && this.$route.name === 'edit-attendance') {
        this.$router.replace({ name: 'mark-attendance', replace: true });
      }

      this.students = response.data.data.students.map(std => ({
        ...std,
        ...(!this.isEditable && {
          status: null,
          comments: ''
        })
      }));

      this.isLoading = false;
    },

    async save() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const data = {
          program: this.program,
          tag: this.tag_id,
          date: formatToAPIDate(this.date),
          students: [...new Set(this.updatedStudents)].map(student => {
            return {
              student: student.student.id,
              status: student.status,
              comments: student.comments
            };
          })
        };

        if (this.isEditable) {
          await this.updateAttendance(data);
        } else {
          await this.markAttendance(data);
        }

        this.makeToast({ variant: 'success', msg: 'Attendance Saved Successfully!.' });
        setTimeout(() => {
          this.$router.push({ name: 'view-attendance', params: { program: this.program, tag: this.tag_id } });
        }, 1000);
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please mark attendance for all records.' });
      }
    },

    async confirmDelete() {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          `Are you sure you want to delete attendance log for "${this.tag_id}" of "${formatToAPIDate(this.date)}"?`,
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          }
        );
        if (isConfirmed) {
          const data = {
            program: this.program,
            tag: this.tag_id,
            date: formatToAPIDate(this.date)
          };
          await this.deleteAttendance(data);

          this.makeToast({ variant: 'success', msg: 'Attendance Successfully Deleted!' });

          setTimeout(() => {
            this.$router.push({
              name: 'view-attendance',
              params: { program: this.program, tag: this.tag_id }
            });
          }, 1000);
        }
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchStudents();
    },

    onSearch() {
      this.debouncedSearchStudentReqDocs(this);
    },

    debouncedSearchStudentReqDocs: debounce(vm => {
      vm.fetchStudents();
    }, 500),

    handleResize() {
      this.windowWidth = window.innerWidth;
    },

    handleChange(newVal) {
      this.updatedStudents.push(newVal);
      if (
        (this.students.filter(student => student.status === 'A') || []).length &&
        (this.students.filter(student => student.status === 'P') || []).length
      ) {
        this.markAll = null;
        this.key = this.key + 1;
      }
    },
    updateWatchers() {
      const getItem = val => val.item || val;

      differenceBy(this.students, this.watchTracker, getItem).forEach(item => {
        const unwatch = this.$watch(() => item, this.handleChange, { deep: true });
        this.watchTracker.push({ item: item, unwatch: unwatch });
      });

      differenceBy(this.watchTracker, this.students, getItem).forEach(watchObj => {
        watchObj.unwatch();
        pull(this.watchTracker, watchObj);
      });
    }
  },

  async mounted() {
    await this.fetchPrograms();

    if (this.$route.params.date && this.$route.params.program && this.$route.params.tag) {
      this.tagFilter = this.$route.params.tag;
      await this.fetchTags();

      this.date = new Date(this.$route.params.date);
      this.program = this.$route.params.program;
      this.tag_id = this.$route.params.tag;

      this.fetchStudents();
    } else {
      this.date = new Date();
    }

    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>

<style scoped lang="scss">
.loader {
  text-align: center;
  color: #bbbbbb;
  margin-inline: auto;
}
</style>
