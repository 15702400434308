<template>
  <b-modal ref="docConfigModal" hide-footer :title="docTitle" centered lazy @hide="hideModal" size="lg">
    <div class="mb-2">
      <b-form-group label="Title" label-for="title" label-class="form-label">
        <b-form-input id="name" placeholder="Please Enter Title" v-model="title" required />
      </b-form-group>

      <b-form-group>
        <b-form-checkbox id="checkbox" v-model="isDateReq" name="checkbox" switch>
          Mark Expiration Date as Required?
        </b-form-checkbox>
      </b-form-group>
    </div>
    <page-separator title="Program Wise Document Configuration" />
    <div class="row">
      <b-form-group v-for="(pg, index) in pgConfig" :key="`pg-${index}`" class="col-md-6">
        <label class="form-label"> {{ pg.program.title }} </label>

        <div class="custom-select-icon">
          <b-form-select
            class="custom-select-icon__select"
            v-model="pg.status"
            :options="typeOptions"
            required
          ></b-form-select>
          <span class="material-icons custom-select-icon__icon">sort</span>
        </div>
      </b-form-group>
    </div>
    <div class="d-flex justify-content-end mt-2">
      <b-btn
        variant="primary"
        class="ml-2"
        style="width: 120px"
        @click.prevent="
          () => {
            isQuestionsAdded = true;
            questions = [];
            questions.push('');
          }
        "
        href="#"
        size="sm"
        :disabled="isQuestionsAdded"
      >
        <span>{{ 'Add Questions' }}</span></b-btn
      >
    </div>

    <div v-if="isQuestionsAdded">
      <b-form-group label="Questions" label-for="questions" label-class="form-label">
        <div class="d-flex mb-2" v-for="(question, index) in questions" :key="`question-${index}`">
          <b-form-input
            class="col-9 col-md-10"
            id="name"
            placeholder="Please Enter Question"
            v-model="questions[index]"
            required
          >
          </b-form-input>

          <a class="btn btn-primary btn-md ml-2" href="#" @click.prevent="addField">
            <md-icon>add</md-icon>
          </a>
          <a href="#" class="btn btn-danger ml-2 btn-md" @click.prevent="removeField(questions[index], index)">
            <md-icon>close</md-icon>
          </a>
        </div>
      </b-form-group>
    </div>

    <div class="d-flex justify-content-start mt-2">
      <b-btn
        variant="primary"
        class="ml-2"
        style="width: 70px"
        @click.prevent="submitData"
        href="#"
        data-toggle="modal"
        :disabled="isLoading"
      >
        <span>{{ 'save' }}</span></b-btn
      >
    </div>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex';
import { get } from 'lodash';
import { PII_TYPE_CONFIG_OPTIONS } from '@/common/constants';
import PageSeparator from '@/components/Ui/PageSeparator.vue';

export default {
  name: 'DocConfigModal',
  components: { PageSeparator },

  props: {
    showModal: { type: Boolean, default: false },
    isDocDateReq: { type: Boolean, default: false },
    index: { type: Number, default: null },
    docTitle: { type: String, default: () => '' },
    existingQuestions: { type: Array, default: () => [] },
    programConfig: { type: Array, default: () => [] },
  },

  data() {
    return {
      pgConfig: [],
      questions: [],
      isDateReq: false,
      updated: false,
      isLoading: false,
      title: '',
      isQuestionsAdded: false,
      typeOptions: PII_TYPE_CONFIG_OPTIONS,
    };
  },
  validations() {
    return {};
  },
  methods: {
    ...mapActions('school', ['updateSclConfigPiisLabel']),
    get,
    addField() {
      this.questions.push('');
    },
    async removeField(data, index) {
      if (data) {
        try {
          const isConfirmed = await this.$bvModal.msgBoxConfirm(`Please confirm that you want to delete it.`, {
            title: 'Are you sure?',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'success',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          });
          if (isConfirmed) {
            if (index === 0 && this.questions.length === 1) {
              this.isQuestionsAdded = false;
              this.questions = [];
            } else if (index > -1) {
              this.questions.splice(index, 1);
            }
          }
        } catch (err) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      } else {
        if (index === 0 && this.questions.length === 1) {
          this.isQuestionsAdded = false;
          this.questions = [];
        } else if (index > -1) {
          this.questions.splice(index, 1);
        }
      }
    },

    async submitData() {
      this.isLoading = true;
      try {
        const data = {
          title: this.title,
          custom_questions: this.questions ? this.questions : [],
          config_programs: this.pgConfig.map((x) => ({
            program: x.program.id,
            status: x.status,
          })),
          req_expiry_date: this.isDateReq,
        };

        await this.updateSclConfigPiisLabel({ id: this.index, data });

        this.makeToast({ variant: 'success', msg: 'Document Updated!' });
        this.hideUpdateModal();
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },
    resetData() {
      this.$refs.docConfigModal.hide();
      this.questions = [];
      this.isQuestionsAdded = false;
    },
    async hideModal() {
      this.resetData();
      this.$emit('close');
    },
    async hideUpdateModal() {
      this.resetData();
      this.$emit('closeUpdate');
    },

    hideModalManual() {
      this.$refs.docConfigModal.hide();
      this.hideUpdateModal();
    },
  },
  watch: {
    showModal(value) {
      if (value) {
        this.title = this.docTitle;
        this.isDateReq = this.isDocDateReq;

        if (this.existingQuestions.length) {
          this.isQuestionsAdded = true;
        }
        this.questions = this.existingQuestions.length ? this.existingQuestions : [];
        this.pgConfig = this.programConfig;
        this.$refs.docConfigModal.show();
      }
    },
  },
  mounted() {},
};
</script>

<style>
</style>