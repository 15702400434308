<template>
  <div>
    <page-header :title="title" :container-class="containerClass" />
    <div class="page-section">
      <div :class="containerClass">
        <page-separator title="" />
        <b-skeleton-wrapper :loading="isLoading">
          <template #loading>
            <div class="row">
              <div class="col-lg-2">
                <div class="d-flex justify-content-center">
                  <user-avatar
                    slot="aside"
                    size="5rem"
                    :user="signUpRequest.user"
                    first-name-field="first_name"
                    last-name-field="last_name"
                    :text="getInitials()"
                  />
                </div>

                <div class="my-3">
                  <b-skeleton type="button" width="100%"></b-skeleton>
                </div>
              </div>

              <div class="col-lg-10 d-flex mt-3">
                <div class="flex" style="max-width: 100%">
                  <h3><b-skeleton width="100%"></b-skeleton></h3>
                  <div class="mb-0 mt-3" style="text-transform: none">
                    <b-skeleton width="100%"></b-skeleton>
                    <b-skeleton width="55%"></b-skeleton>
                    <b-skeleton width="70%"></b-skeleton>
                    <b-skeleton width="65%"></b-skeleton>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div class="row">
            <div class="col-lg-2">
              <div class="d-flex justify-content-center">
                <user-avatar
                  slot="aside"
                  size="5rem"
                  :user="signUpRequest.user"
                  first-name-field="first_name"
                  last-name-field="last_name"
                  :text="getInitials()"
                />
              </div>

              <div class="d-flex justify-content-center my-2">
                <b-badge
                  :class="` border border-${signUpRequestStatusColors[signUpRequest.status]} text-${
                    signUpRequestStatusColors[signUpRequest.status]
                  }`"
                  variant="none"
                  v-b-popover.hover.top
                  :title="$t(`signUpRequestStatusDesc.${signUpRequest.status}`)"
                  >{{ $t(`signUpRequestStatusChoice.${signUpRequest.status}`) }}
                </b-badge>
              </div>

              <div class="mb-3">
                <b-btn
                  v-if="signUpRequest.status === SIGN_UP_REQUEST_STATUS.PENDING"
                  variant="success"
                  :disabled="isFormLoading"
                  class="mt-2 w-100 mr-4 btn-normal"
                  type="submit"
                  @click.prevent="updateStatus({ approvalStatus: SIGN_UP_REQUEST_STATUS.APPROVED })"
                >
                  <i v-if="isStatusUpdating" class="fas fa-circle-notch fa-spin"></i>
                  <span v-else>Approve</span>
                </b-btn>

                <b-btn
                  v-if="signUpRequest.status === SIGN_UP_REQUEST_STATUS.PENDING"
                  variant="danger"
                  :disabled="isFormLoading"
                  class="mt-2 w-100 mr-4 btn-normal"
                  type="submit"
                  @click.prevent="updateStatus({ approvalStatus: SIGN_UP_REQUEST_STATUS.REJECTED })"
                >
                  <i v-if="isStatusUpdating" class="fas fa-circle-notch fa-spin"></i>
                  <span v-else>Reject</span>
                </b-btn>
              </div>
            </div>

            <div class="col-lg-10 d-flex">
              <div class="flex" style="max-width: 100%">
                <div class="mb-0">
                  <h3>{{ signUpRequest.full_name }}</h3>
                  <p class="" style="font-size: 15px">
                    <strong class="text-primary mr-1">Phone: </strong
                    >{{ signUpRequest.user ? signUpRequest.user.phone : signUpRequest.phone }}
                  </p>
                  <p class="" style="font-size: 15px">
                    <strong class="text-primary mr-1">Email: </strong>{{ signUpRequest.email }}
                  </p>
                  <p class="" style="font-size: 15px" v-if="signUpRequest.status !== SIGN_UP_REQUEST_STATUS.PENDING">
                    <strong class="text-primary mr-1">Reviewed At: </strong
                    >{{ formatDateTime(signUpRequest.reviewed_at) }}
                  </p>
                  <p class="" style="font-size: 15px" v-if="signUpRequest.status !== SIGN_UP_REQUEST_STATUS.PENDING">
                    <strong class="text-primary mr-1">Reviewed By: </strong>{{ signUpRequest.reviewed_by.first_name }}
                    {{ signUpRequest.reviewed_by.last_name }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>
      </div>
    </div>
  </div>
</template>


<script>
import { get } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import PageHeader from '@/components/Ui/PageHeader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import Page from '../../../components/Page.vue';
import UserAvatar from '../../../components/User/UserAvatar.vue';
import DefaultAvatar from '@/assets/images/logos/logo_icon.png';
import { SIGN_UP_REQUEST_STATUS, SIGN_UP_REQUEST_STATUS_COLORS } from '../../../common/constants';
import { formatDateTime } from '../../../common/utils';
import 'vue-swatches/dist/vue-swatches.css';

export default {
  components: {
    PageHeader,
    PageSeparator,
    UserAvatar,
  },
  extends: Page,

  data() {
    return {
      title: 'Review Account',
      DefaultAvatar,
      isLoading: false,
      isStatusUpdating: false,

      signUpRequest: {
        email: '',
        full_name: '',
        status: '',
        user: {
          first_name: '',
          last_name: '',
          phone: '',
          avatar_url: '',
        },
        reviewed_at: '',
        reviewed_by: {
          first_name: '',
          last_name: '',
        },
      },
      SIGN_UP_REQUEST_STATUS,
      signUpRequestStatusColors: SIGN_UP_REQUEST_STATUS_COLORS,
    };
  },

  computed: {
    ...mapGetters('school', ['getCurrentSchool']),
    ...mapGetters('auth', ['getLoggedInUser']),
    breadcrumb() {
      return [
        { text: 'Home', to: this.routes.home },
        {
          text: 'Sign-up Requests',
          to: { name: 'manage-student-signup-list' },
        },
        {
          text: 'Review Account',
          active: true,
        },
      ];
    },

    isFormLoading() {
      return this.isLoading || this.isStatusUpdating;
    },
  },

  methods: {
    ...mapActions('signUpRequest', ['getSignUpRequest', 'updateSignUpRequestStatus']),
    get,
    formatDateTime,

    getInitials() {
      const arr = this.signUpRequest.full_name.split(' ');
      return get(arr, '0.0', '') + get(arr, '1.0', '');
    },

    async updateStatus({ approvalStatus = undefined }) {
      this.isStatusUpdating = true;
      try {
        let isConfirmed = true;
        if (approvalStatus === SIGN_UP_REQUEST_STATUS.REJECTED) {
          isConfirmed = await this.$bvModal.msgBoxConfirm(
            'Please confirm that you want to reject this account request.',
            {
              title: 'Are you sure?',
              size: 'md',
              buttonSize: 'sm',
              okVariant: 'danger',
              okTitle: 'Yes',
              cancelTitle: 'No',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true,
            }
          );
        }
        if (isConfirmed) {
          const data = {
            status: approvalStatus,
          };
          await this.updateSignUpRequestStatus({ id: this.$route.params.id, data });
          this.signUpRequest = {
            ...this.signUpRequest,
            ...data,
            reviewed_at: new Date(),
            reviewed_by: { first_name: this.getLoggedInUser.first_name, last_name: this.getLoggedInUser.last_name },
          };
          if (approvalStatus === SIGN_UP_REQUEST_STATUS.APPROVED) {
            this.makeToast({ variant: 'success', msg: 'Sign Up Request has been approved.' });
          } else if (approvalStatus === SIGN_UP_REQUEST_STATUS.REJECTED) {
            this.makeToast({ variant: 'success', msg: 'Sign Up Request has been rejected.' });
          }
        }
      } catch ($e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isStatusUpdating = false;
    },

    async fetchSignUpRequest() {
      this.isLoading = true;
      try {
        const res = await this.getSignUpRequest(this.$route.params.id);
        this.signUpRequest = res.data;
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },
  },

  async mounted() {
    this.fetchSignUpRequest();
  },
};
</script>
