
<template>
  <b-modal ref="secureDocModal" hide-footer title="Resumes" centered lazy scrollable @hide="hideModal" size="lg">
    <b-skeleton-img class="w-100" height="500px" v-if="isUrlLoading || isLoading" no-aspect></b-skeleton-img>

    <div
      v-for="re in resumes"
      :key="re.id"
      class="d-flex event-mod-item pt-2 px-1 clickable-item"
      @click.prevent="openDocModal(re.resume_link, re.program_name)"
      v-else
    >
      <div class="d-flex align-items-center">
        <strong class="ml-2 mb-2">{{ `${re.program_name} - ${formatDateTime(re.date_generated)}` }} </strong>
      </div>
    </div>
  </b-modal>
</template>

<script>
import mime from 'mime';
import pdf from '@teckel/vue-pdf';
import { get } from 'lodash';
import { parseFileNameFromUrl, formatDateSimple, formatDateTime } from '@/common/utils';
import { mapActions } from 'vuex';

export default {
  name: 'SecureDocModal',

  props: {
    showModal: { type: Boolean, default: false },
    resumes: { type: Array, default: () => [] },
  },

  data() {
    return {
      isLoading: false,
      imageSrc: null,
      pdfSrc: null,
      numPages: 1,
      showSecurityModal: false,
      isUrlLoading: false,
      fileSrc: null,
      questions: [],
    };
  },

  computed: {},

  watch: {
    async showModal(value) {
      if (value) {
        this.$refs.secureDocModal.show();
      }
    },
  },

  methods: {
    get,
    ...mapActions('fileDownload', ['downloadFile']),
    ...mapActions('student', ['getStudentDocUrl']),
    formatDateSimple,
    formatDateTime,
    openDocModal(resume, program_name) {
      this.$emit('openDoc', resume, program_name);
    },
    async openDoc() {
      this.isUrlLoading = true;
      try {
        this.pdfSrc = pdf.createLoadingTask(this.fileSrc);
        this.pdfSrc.promise.then((loadedPdf) => {
          this.numPages = loadedPdf.numPages;
          this.isLoading = false;
        });
      } catch (err) {
        if (get(err.response, 'status') === 403) {
          this.$emit('verification', this.documentTitle, this.id, this.documentType);
          this.hideModal();
        } else this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isUrlLoading = false;
    },
    hideModal() {
      this.$emit('close');
      setTimeout(() => this.$refs.secureDocModal.hide());

      this.imageSrc = this.pdfSrc = null;
      this.numPages = 1;
      this.isLoading = false;
    },

    hideModalManual() {
      this.$refs.secureDocModal.hide();
      this.hideModal();
    },

    getFileType() {
      if (!this.fileSrc) return;
      const type = mime.getType(parseFileNameFromUrl(this.fileSrc).split('.').pop());

      if (type && type.includes('image')) {
        return 'img';
      } else if (type && type.includes('pdf')) {
        return 'pdf';
      }

      return null;
    },

    async downloadGivenFile() {
      const h = this.$createElement;

      this.makeToast({
        variant: 'primary',
        msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'Downloading File']),
        toaster: 'b-toaster-bottom-left',
      });

      await this.downloadFile({ fileUrl: this.fileSrc, removeTimestamp: true });

      this.makeToast({
        variant: 'success',
        msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'File Downloaded']),
        toaster: 'b-toaster-bottom-left',
      });
    },
  },

  async mounted() {},
};
</script>




