<template>
  <div>
    <page-header :title="title" :container-class="containerClass" infoText="Manage and view student testimonials." />
    <div class="page-section">
      <div :class="containerClass">
        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input
                    class="w-lg-auto"
                    :placeholder="'Search Testimonials'"
                    v-model="searchTerm"
                    @input="onSearch"
                  />
                  <b-btn variant="flush" type="submit">
                    <md-icon v-text="'search'" />
                  </b-btn>
                </form>
              </div>
            </div>
          </div>

          <!-- Testimonials Table -->
          <b-table
            :fields="tableColumns"
            :items="testimonials"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap"
            hover
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
            @row-clicked="showPage"
          >
            <template #cell(logo)="data">
              <user-avatar slot="aside" size="md" :user="data.item.created_by"> </user-avatar>
            </template>

            <template #cell(name)="data">
              {{ data.item.created_by.first_name }} {{ data.item.created_by.last_name }}
            </template>

            <template #cell(approval_status)="data">
              <b-badge
                :class="` border border-${testimonialStatusColors[getStatus(data)]} text-${
                  testimonialStatusColors[getStatus(data)]
                }`"
                variant="none"
                v-b-popover.hover.top
                :title="$t(`testimonalStatusDesc.${getStatus(data)}`)"
                >{{ $t(`testimonalStatusChoice.${getStatus(data)}`) }}
              </b-badge>
            </template>
            <template #cell(reveiwed_by)="data">
              {{ get(data, 'item.reveiwed_by') ? data.item.reveiwed_by : null }}
            </template>

            <template #head(actions)="">
              <span></span>
            </template>
            <template #cell(actions)="data">
              <a
                href="#"
                @click.prevent="
                  updateStatus({ id: data.item.id, approvalStatus: TESTIMONIAL_STATUSES.APPROVED, isVisible: true })
                "
                v-b-popover.hover.left
                :title="$t('testimonalMsgs.approve')"
                v-if="
                  data.item.approval_status === TESTIMONIAL_STATUSES.PENDING ||
                  data.item.approval_status === TESTIMONIAL_STATUSES.REJECTED
                "
              >
                <md-icon class="text-success">check</md-icon>
              </a>
              <a
                href="#"
                @click.prevent="
                  updateStatus({ id: data.item.id, approvalStatus: TESTIMONIAL_STATUSES.REJECTED, isVisible: false })
                "
                v-b-popover.hover.left
                :title="$t('testimonalMsgs.reject')"
                v-if="data.item.approval_status === TESTIMONIAL_STATUSES.PENDING"
              >
                <md-icon class="text-danger">close</md-icon>
              </a>
              <a
                href="#"
                @click.prevent="updateStatus({ id: data.item.id, isVisible: false })"
                v-b-popover.hover.left
                :title="$t('testimonalMsgs.hide')"
                v-if="data.item.approval_status === TESTIMONIAL_STATUSES.APPROVED && data.item.is_visible === true"
              >
                <md-icon>visibility_off</md-icon>
              </a>
              <a
                href="#"
                @click.prevent="updateStatus({ id: data.item.id, isVisible: true })"
                v-b-popover.hover.left
                :title="$t('testimonalMsgs.show')"
                v-if="data.item.approval_status === TESTIMONIAL_STATUSES.APPROVED && data.item.is_visible === false"
              >
                <md-icon>visibility</md-icon>
              </a>
            </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalTestimonial"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="testimonials-table"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import { debounce, get } from 'lodash';
import { mapGetters, mapActions } from 'vuex';

//import { FmvAvatar } from 'fmv-avatar';
import Page from '@/components/Page.vue';
import Pagination from '../../../components/Ui/Pagination.vue';
import MdIcon from '../../../components/Ui/MdIcon.vue';
import {
  DEFAULT_PAGE_SIZE,
  TESTIMONIAL_STATUSES_COLORS,
  TESTIMONIAL_STATUSES,
  USER_ROLES,
} from '../../../common/constants';
import { formatDateTime } from '../../../common/utils';
import UserAvatar from '../../../components/User/UserAvatar.vue';
export default {
  components: { MdIcon, PageHeader, Pagination, UserAvatar },
  extends: Page,

  name: 'TestimonialList',

  data() {
    return {
      title: 'Testimonials',
      USER_ROLES,
      isLoading: false,
      isResendingAll: false,

      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,

      testimonialStatusColors: TESTIMONIAL_STATUSES_COLORS,
      totalTestimonial: 0,
      testimonials: [],

      searchTerm: '',

      isCopied: false,
      TESTIMONIAL_STATUSES,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    breadcrumb() {
      return [
        { text: 'Home', to: this.routes.home },
        { text: 'Manage Testimonials', active: true },
      ];
    },

    tableColumns() {
      return [
        { key: 'logo', label: '' },
        { key: 'name', label: 'Name', tdClass: 'clickable-item' },
        { key: 'approval_status', label: 'Status', tdClass: 'clickable-item' },
        {
          key: 'reviewed_by',
          label: 'Reviewed By',
          tdClass: 'clickable-item',
          formatter: (value) => (value ? `${value.first_name} ${value.last_name}` : value),
        },
        {
          key: 'created_at',
          label: 'Written On',
          tdClass: 'clickable-item',
          formatter: (value) => formatDateTime(value),
        },
        ...(get(this.getLoggedInUser, 'role') === USER_ROLES.SCHOOL_OWNER
          ? [
              {
                key: 'actions',
                tdClass: 'text-right',
                thStyle: { minWidth: '100px' },
              },
            ]
          : []),
      ];
    },
  },

  methods: {
    ...mapActions('testimonial', ['getAllTestimonials', 'updateTestimonialStatus']),
    get,

    showPage(row) {
      this.$router.push({ name: 'show-student-testimonial', params: { id: row.id } });
    },

    getStatus(data) {
      return data.item.approval_status === TESTIMONIAL_STATUSES.PENDING
        ? TESTIMONIAL_STATUSES.PENDING
        : data.item.approval_status === TESTIMONIAL_STATUSES.REJECTED
        ? TESTIMONIAL_STATUSES.REJECTED
        : data.item.approval_status === TESTIMONIAL_STATUSES.APPROVED && data.item.is_visible === true
        ? TESTIMONIAL_STATUSES.VISIBLE
        : TESTIMONIAL_STATUSES.HIDDEN;
    },

    async updateStatus({ id, approvalStatus = undefined, isVisible = undefined }) {
      this.isStatusUpdating = true;
      try {
        let isConfirmed = true;
        if (approvalStatus === TESTIMONIAL_STATUSES.REJECTED) {
          isConfirmed = await this.$bvModal.msgBoxConfirm(
            'Please confirm that you want to reject the selected testimonial.',
            {
              title: 'Are you sure?',
              size: 'md',
              buttonSize: 'sm',
              okVariant: 'danger',
              okTitle: 'Yes',
              cancelTitle: 'No',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true,
            }
          );
        }

        if (isConfirmed) {
          const data = {
            ...(approvalStatus && { approval_status: approvalStatus }),
            ...(isVisible !== undefined && { is_visible: isVisible }),
          };

          await this.updateTestimonialStatus({ id, data });
          this.fetchTestimonials();

          if (approvalStatus === TESTIMONIAL_STATUSES.APPROVED) {
            this.makeToast({ variant: 'success', msg: 'Testimonial has been approved.' });
          } else if (approvalStatus === TESTIMONIAL_STATUSES.REJECTED) {
            this.makeToast({ variant: 'success', msg: 'Testimonial has been rejected.' });
          } else if (isVisible) {
            this.makeToast({ variant: 'success', msg: 'Testimonial is now visible.' });
          } else {
            this.makeToast({ variant: 'success', msg: 'Testimonial hidden.' });
          }
        }
      } catch ($e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isStatusUpdating = false;
    },

    async fetchTestimonials(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getAllTestimonials({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params,
      });
      this.testimonials = response.data.results;
      this.currentPage = pageNum;
      this.totalTestimonial = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchTestimonials(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchTestimonials();
    },

    onSearch() {
      this.debouncedSearchTestimonials(this);
    },

    debouncedSearchTestimonials: debounce((vm) => {
      vm.fetchTestimonials();
    }, 500),
  },

  async mounted() {
    this.fetchTestimonials();
  },
};
</script>
