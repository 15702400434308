<template>
  <div>
    <page-header :title="title" :container-class="containerClass" :breadcrumb="breadcrumb" />

    <div class="page-section">
      <div :class="containerClass">
        <div class="d-flex justify-content-center" v-if="redeemedSuccess">
          <div class="card card-block card-stretch card-height col-12">
            <div class="card-body text-center">
              <i class="fas fa-check-circle fa-lg text-success mt-4 mb-4" style="font-size: 3rem" />
              <h4>Reward Redeemed</h4>

              <p class="text-muted">
                <b>{{ redeemedPrize.title }}</b> successfully redeemed. We'll be delivering it to you shortly.
              </p>

              <router-link :to="{ name: 'home' }" class="btn btn-primary" exact
                ><md-icon class="icon-16pt mr-1">home</md-icon> Back to Home
              </router-link>
            </div>
          </div>
        </div>

        <div class="row card-group-row mb-16pt mb-lg-40pt" v-else-if="get(prizes, 'length')">
          <div class="col-lg-4 col-sm-6 card-group-row__col" v-for="cr in prizes" :key="cr.id">
            <reward-card
              :title="cr.title"
              :image="cr.image"
              :description="cr.description"
              :credits="cr.total_credits"
              :action-disabled="isCreditsLoading"
              :prize="cr"
              :is-loading="isLoading"
              class="mb-lg-0 border-0"
              @onAction="redeemPrize"
            />
          </div>
        </div>

        <div class="d-flex justify-content-center" v-else-if="!get(prizes, 'length') && !isLoading">
          <div class="card card-block card-stretch card-height col-md-8">
            <div class="card-body text-center">
              <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
              <h4>No Rewards Available</h4>
              <p class="text-muted">
                No active rewards are currently available. Please check again later to redeem your credits.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { get } from 'lodash';
import RewardCard from './RewardCard.vue';
import PageHeader from '../../components/Ui/PageHeader.vue';
import Page from '@/components/Page.vue';
//import { PAYMENT_STATUSES } from '../../common/constants';

export default {
  components: { PageHeader, RewardCard },
  extends: Page,
  name: 'RedeemCredits',

  data() {
    return {
      title: 'Redeem Rewards',

      prizes: [],
      isCreditsLoading: false,
      isLoading: false,
      arePrizesLoading: false,
      purchaseSuccess: false,
      purchasedTokens: 0,
      redeemedPrize: null,
      redeemedSuccess: false,
    };
  },

  computed: {
    ...mapGetters('credit', ['getAvailableCredits']),
    breadcrumb() {
      return [{ text: this.isCreditsLoading ? '' : `Available Credits: ${this.getAvailableCredits}`, active: true }];
    },
  },

  methods: {
    ...mapActions('credit', ['redeemCredits', 'updateStateCredits', 'getAllPrizes', 'updateStateCredits']),
    get,

    async fetchAvailableCredits() {
      this.isCreditsLoading = true;

      await this.updateStateCredits();

      this.isCreditsLoading = false;
    },
    async fetchPrizes() {
      this.arePrizesLoading = true;

      const response = await this.getAllPrizes({});
      this.prizes = response.data;

      this.arePrizesLoading = false;
    },

    async redeemPrize(prize) {
      this.isLoading = true;

      const isConfirmed = await this.$bvModal.msgBoxConfirm(
        `You are about to redeem ${prize.title} for ${prize.total_credits} credit(s). Please click 'Continue' to confirm.`,
        {
          title: 'Are you sure?',
          size: 'md',
          buttonSize: 'sm',
          cancelVariant: 'light',
          okVariant: 'primary',
          okTitle: 'Continue',
          cancelTitle: 'Go Back',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        }
      );
      if (isConfirmed) {
        try {
          //redeem credits api
          const resp = await this.redeemCredits({ id: prize.id });

          if (resp.status_code === 200) {
            // If tokens successfully purchased. updateCredits()=>
            this.updateStateCredits();
            this.redeemedPrize = prize;
            this.redeemedSuccess = true;
          }
        } catch (err) {
          // if (get(err, 'response.data.data.status') === PAYMENT_STATUSES.PAYMENT_FAILURE) {
          //   this.makeToast({
          //     variant: 'danger',
          //     msg: 'Cannot complete tokens purchase due to payment issue. Please update your payment method and try again.',
          //   });
          // }
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      }

      this.isLoading = false;
    },
  },

  async mounted() {
    await this.fetchAvailableCredits();
    this.fetchPrizes();
  },
};
</script>
