
<template>
  <b-modal ref="previewDocModal" hide-footer title="Preview" centered lazy scrollable @hide="hideModal" size="lg">
    <b-skeleton-img class="w-100" height="500px" v-if="isUrlLoading || isLoading" no-aspect></b-skeleton-img>

    <div v-for="page in numPages" :key="page">
      <pdf :src="pdfSrc" :page="page" class="w-100 mt-2"></pdf>
      <hr v-if="page < numPages" />
    </div>
  </b-modal>
</template>

<script>
// import mime from 'mime';
import pdf from '@teckel/vue-pdf';
import { get } from 'lodash';
import { formatDateSimple, formatDateTime } from '@/common/utils';
import { mapActions } from 'vuex';

export default {
  name: 'PreviewDocModal',

  components: { pdf },

  props: {
    showModal: { type: Boolean, default: false },
    fileSrc: { type: String, default: null },
  },

  data() {
    return {
      isLoading: false,
      imageSrc: null,
      pdfSrc: null,
      numPages: 1,
      showSecurityModal: false,
      isUrlLoading: false,
    };
  },

  computed: {},

  watch: {
    async showModal(value) {
      if (value) {
        this.openDoc();
        this.$refs.previewDocModal.show();
      }
    },
  },

  methods: {
    get,
    ...mapActions('fileDownload', ['downloadFile']),
    ...mapActions('student', ['getStudentDocUrl']),
    formatDateSimple,
    formatDateTime,
    async openDoc() {
      this.isUrlLoading = true;
      try {
        this.pdfSrc = pdf.createLoadingTask(this.fileSrc);
        this.pdfSrc.promise.then((loadedPdf) => {
          this.numPages = loadedPdf.numPages;
          this.isLoading = false;
        });
      } catch (err) {
        if (get(err.response, 'status') === 403) {
          //  this.$emit('verification', this.documentTitle, this.id, this.documentType);
          this.hideModal();
        } else this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isUrlLoading = false;
    },
    hideModal() {
      this.$emit('close');
      setTimeout(() => this.$refs.previewDocModal.hide());

      this.imageSrc = this.pdfSrc = null;
      this.numPages = 1;
      this.isLoading = false;
    },

    hideModalManual() {
      this.$refs.previewDocModal.hide();
      this.hideModal();
    },

    async downloadGivenFile() {
      const h = this.$createElement;

      this.makeToast({
        variant: 'primary',
        msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'Downloading File']),
        toaster: 'b-toaster-bottom-left',
      });

      await this.downloadFile({ fileUrl: this.fileSrc, removeTimestamp: true });

      this.makeToast({
        variant: 'success',
        msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'File Downloaded']),
        toaster: 'b-toaster-bottom-left',
      });
    },
  },

  async mounted() {},
};
</script>




