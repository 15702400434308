<template>
  <div>
    <page-header
      :title="title"
      :container-class="containerClass"
      info-text="Configure the skills that your students can practice and share their progress/practice videos."
    />
    <div class="page-section">
      <div :class="containerClass">
        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input class="w-lg-auto" placeholder="Search skill" v-model="searchTerm" @input="onSearch" />

                  <b-btn variant="flush" type="submit">
                    <md-icon>search</md-icon>
                  </b-btn>
                </form>
              </div>

              <!-- Filters and Add Btn -->
              <div class="col-lg d-flex flex-wrap justify-content-end">
                <div class="col-lg-6 mx-0 px-0"></div>

                <b-btn class="ml-2" :to="{ name: 'new-skill-config' }" exact variant="primary">Configure Skill</b-btn>
              </div>
            </div>
          </div>

          <b-table
            :fields="tableColumns"
            :items="allSkills"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap sticky-lcol-table"
            hover
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
            @row-clicked="editSkill"
            show-empty
          >
            <template #table-busy>
              <div class="text-center">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>

            <template #empty>
              <div class="text-center">No skills configured.</div>
            </template>

            <template #cell(thumbnail)="data">
              <img
                :src="data.item.sample_video_thumbnail_url"
                class="rounded"
                style="width: 40px; height: 40px"
                v-if="data.item.sample_video_thumbnail_url"
              />
              <video :src="data.item.sample_video_url" class="rounded" style="width: 40px; height: 40px" v-else></video>
            </template>

            <!-- <template #head(actions)="">
              <span></span>
            </template> -->

            <template #cell(actions)="data">
              <router-link
                :to="{ name: 'edit-skill-config', params: { id: data.item.id } }"
                class="svg-icon mr-2"
                v-b-popover.hover.right
                :title="'Edit'"
              >
                <i class="mr-1 material-icons">edit</i>
              </router-link>
              <a href="#" @click.prevent="removeSkill(data.item.id)" v-b-popover.hover.right :title="'Remove'">
                <i class="material-icons text-danger">delete</i>
              </a>
            </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination v-model="currentPage" :total-rows="totalSkills" :per-page="perPage" @change="onPageChange" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DEFAULT_PAGE_SIZE } from '@/common/constants';
import Page from '@/components/Page.vue';
import MdIcon from '@/components/Ui/MdIcon.vue';
import PageHeader from '@/components/Ui/PageHeader.vue';
import Pagination from '@/components/Ui/Pagination.vue';
import { debounce } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: { MdIcon, PageHeader, Pagination },
  extends: Page,

  data() {
    return {
      title: 'Skills Configuration',

      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalSkills: 0,
      searchTerm: '',
      allSkills: [],
      isUrlCopied: false,
      inviteId: '',
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),

    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Skills', active: true },
      ];
    },

    tableColumns() {
      return [
        { key: 'thumbnail', label: '', tdClass: 'clickable-item', thStyle: { width: '60px' } },

        { key: 'title', label: 'Name', tdClass: 'clickable-item' },
        { key: 'description', label: 'Description', tdClass: 'clickable-item' },

        { key: 'actions', label: '', tdClass: 'text-right', thStyle: { width: '70px' } },
      ];
    },
  },

  methods: {
    ...mapActions('skills', ['getSchSkillsConfigs', 'deleteSkill']),

    async fetchSkills(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getSchSkillsConfigs({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params,
      });
      this.allSkills = response.data.results;
      this.currentPage = pageNum;
      this.totalSkills = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchSkills(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchSkills();
    },

    onSearch() {
      this.debouncedSearchSkills(this);
    },

    debouncedSearchSkills: debounce(vm => {
      vm.fetchSkills();
    }, 500),

    editSkill(data) {
      this.$router.push({ name: 'edit-skill-config', params: { id: data.id } });
    },

    async removeSkill(id) {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          `Please confirm that you want to remove the selected Skill. The Skill will no longer appear on your portal.`,
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          },
        );
        if (isConfirmed) {
          this.isLoading = true;
          await this.deleteSkill(id);
          this.fetchSkills();
          this.makeToast({ variant: 'success', msg: 'Skill removed successfully!' });
          this.isLoading = false;
        }
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        this.isLoading = false;
      }
    },
  },

  async mounted() {
    this.fetchSkills();
  },
};
</script>
