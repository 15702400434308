<template>
  <div class="card card-group-row__card text-center o-hidden">
    <span class="js-image d-flex justify-content-center" data-position="left" :data-height="168">
      <img :src="image" alt="cna" class="career-card-img" />
    </span>

    <div class="card-body d-flex flex-column">
      <div class="flex-grow-1">
        <!-- In case if rounded circle images are preferred. -->
        <!-- <a
          href="#"
          @click.prevent
          class="avatar avatar-xxl overlay js-overlay overlay--primary rounded-circle p-0 o-hidden mb-3"
          style="border: 1px solid #dfe2e6;id #dfe2e6;id #dfe2e6;pxl"
        >
          <img class="avatar-img m-0" :src="image" width="160" height="auto" />
        </a> -->

        <h4 class="mb-8pt">{{ $t(title) }}</h4>
        <p class="text-black-70">{{ $t(description) }}</p>
      </div>

      <p class="d-flex justify-content-center align-items-center mb-0">
        <span class="text-center h3 m-0 font-weight-normal">
          <i class="fas fa-coins clickable-item" v-b-popover.hover.top title="Credits"></i>
          {{ $n(credits) }}
        </span>
      </p>
    </div>
    <div
      class="card-footer"
      v-b-popover.hover.top="
        getAvailableCredits < credits ? 'You have insufficient credits to redeem this reward.' : ''
      "
    >
      <b-btn
        :disabled="actionDisabled || getAvailableCredits < credits"
        @click.prevent="handleAction"
        class="btn-normal"
        variant="primary"
        style="width: 100px"
        ><i class="fas fa-circle-notch fa-spin" v-if="isLoading" /><span v-else>Redeem</span></b-btn
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  components: {},
  props: {
    title: { type: String, default: null },
    description: { type: String, default: null },
    price: { type: Number, default: null },
    credits: { type: Number, default: null },
    color: { type: String, default: 'accent' },
    icon: { type: String, default: null },
    image: { type: String, default: null },
    iconColor: { type: String, default: null },
    raised: { type: Boolean, default: false },
    ribbon: { type: String, default: null },
    action: { type: String, default: 'Purchase' },
    actionColor: { type: String, default: null },
    actionDisabled: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
    prize: { type: Object },
    jobCredits: { type: Number, default: null },
  },
  computed: {
    ...mapGetters('credit', ['getAvailableCredits']),

    localIconColor() {
      return this.iconColor || `outline-${this.color}`;
    },
    localActionColor() {
      return this.actionColor || this.color;
    },
    iconClass() {
      return `icon-holder--${this.localIconColor}`;
    },
  },

  methods: {
    handleAction() {
      this.$emit('onAction', this.prize);
    },
  },
};
</script>
