<template>
  <b-popover
    v-if="targetId"
    ref="popover"
    :target="targetId"
    triggers="hover"
    :placement="placement"
    :html="true"
    custom-class="popover-lg"
  >
    <div class="">
      {{ info }}
    </div>
  </b-popover>
</template>

<script>
// import DefaultAvatar from '@/assets/images/logos/logo_icon.png';

export default {
  name: 'InfoPopover',
  components: {},

  props: {
    info: { type: String, default: '' },
    targetId: { required: true },
    placement: { type: String, default: 'left' },
  },

  data() {
    return {
      // DefaultAvatar,
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Sample Page', active: true },
      ];
    },
  },

  methods: {},

  async mounted() {},
};
</script>
