<template>
  <b-modal
    ref="contactModal"
    hide-footer
    title="Contact Candidate"
    centered
    lazy
    scrollable
    @hide="hideModal"
    size="md"
  >
    <div v-if="getLoggedInUser.role_type === USER_ROLE_TYPES.ORGANIZATION" class="text-center">
      You must be a partner of
      <b>
        {{ get(school, 'name') }}
      </b>
      to contact this candidate. Please contact support@jointransition.com to become partners.
    </div>

    <div v-else class="text-center">To contact this candidate you need to partner with the school first.</div>
    <div class="d-flex justify-content-center mt-3" @click="gotToPartnershipPage">
      <b-btn variant="primary" v-if="getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER">Partner Now</b-btn>
    </div>
  </b-modal>
</template>

<script>
import { get } from 'lodash';
import { USER_ROLE_TYPES } from '@/common/constants';
import { mapGetters } from 'vuex';
export default {
  name: 'ContactModal',

  props: {
    showModal: { type: Boolean, default: false },
    school: { type: Object },
  },

  data() {
    return {
      isLoading: false,
      USER_ROLE_TYPES,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
  },

  watch: {
    showModal(value) {
      if (value) {
        this.$refs.contactModal.show();
      }
    },
  },

  methods: {
    get,
    hideModal() {
      this.$emit('close');
    },
    gotToPartnershipPage() {
      this.$router.push({ name: 'emp-school-profile', params: { id: this.school.id } });
    },
    hideModalManual() {
      this.$refs.contactModal.hide();
      this.hideModal();
    },
  },

  async mounted() {},
};
</script>
