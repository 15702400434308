<template>
  <div>
    <div :class="containerClass">
      <b-alert show variant="primary"
        >Note: This report will only include those students who have uploaded any of the selected documents.
      </b-alert>
    </div>
    <page-header :title="title" :container-class="containerClass" />
    <div class="page-section">
      <div :class="containerClass">
        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto" :class="{ 'mb-2': isMobSmallScreen }">
                <!-- <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input
                    class="w-lg-auto"
                    :placeholder="'Search Students'"
                    v-model="searchTerm"
                    @input="onSearch"
                  />
                  <b-btn variant="flush" type="submit">
                    <md-icon v-text="'search'" />
                  </b-btn>
                </form> -->
                <date-picker
                  v-model="dateRange"
                  format="DD MMMM, YYYY"
                  valueType="date"
                  id="date"
                  lang="en"
                  placeholder="Start Date Range"
                  range
                  @input="dateChange"
                  style="max-width: 100%"
                ></date-picker>
              </div>

              <!-- Fitler Buttons -->
              <!-- <div class="" :class="isTabSmallScreen ? 'mb-2 col-12 d-flex justify-content-end' : 'col-2 pl-0'">
                <b-btn variant="secondary" @click.prevent="showFilters = true" v-if="!showFilters" size="sm">
                  <i class="fas fa-sliders-h mr-1" />Show Filters
                </b-btn>
                <b-btn variant="light" @click.prevent="showFilters = false" v-if="showFilters" size="sm">
                  <i class="fas fa-eye-slash mr-1" />Hide Filters
                </b-btn>
                <b-btn
                  variant="light"
                  class="ml-2"
                  @click.prevent="clearFilters"
                  v-if="types.length || programs.length"
                  size="sm"
                >
                  <i class="fas fa-times mr-1" />Clear
                </b-btn>
              </div> -->

              <!-- Fetch & Generate Buttons -->
              <div class="col-lg d-flex flex-wrap justify-content-end">
                <b-btn
                  class="btn-normal"
                  style="width: 140px"
                  @click.prevent="onFetchBtnClick"
                  variant="primary"
                  :disabled="!dateRange || isLoading || isReportLoading"
                >
                  <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
                  <span v-else>Fetch Students</span></b-btn
                >
                <b-btn
                  v-if="students.length"
                  class="ml-2 btn-normal"
                  style="width: 150px"
                  @click="generateReport"
                  variant="primary"
                  :disabled="!selected.length || isLoading || isReportLoading"
                >
                  <i v-if="isReportLoading" class="fas fa-circle-notch fa-spin"></i>
                  <span v-else>Generate Report</span></b-btn
                >
              </div>

              <!-- Filters -->
              <!-- <transition name="slide"> -->
              <div class="row mx-auto mb-2 w-100 mt-2">
                <div class="col-md-6 mb-2">
                  <v-select
                    id="programs"
                    class="form-control v-select-custom"
                    label="title"
                    v-model="programs"
                    :reduce="item => item.id"
                    placeholder="All Programs"
                    :options="allPrograms"
                    @input="changeProgram()"
                    multiple
                  >
                  </v-select>
                </div>
                <div class="col-md-6 mb-2" :class="{ 'pl-0': !isTabSmallScreen }">
                  <v-select
                    id="types"
                    class="form-control v-select-custom"
                    label="title"
                    v-model="types"
                    :reduce="item => item"
                    placeholder="All Document Types"
                    :options="typeOptions"
                    multiple
                  >
                  </v-select>
                </div>
                <div class="col-md-6 mb-2">
                  <v-select
                    id="tag"
                    class="form-control v-select-custom"
                    label="title"
                    v-model="tag_id"
                    :value="tag_id"
                    :reduce="sc => sc.id"
                    placeholder="Select Program Tag"
                    :options="allTags"
                    :loading="areTagsLoading && !allTags.length"
                    @search="fetchOptions"
                    :disabled="(areTagsLoading && !allTags.length) || !programs.length"
                  >
                    <template #list-footer>
                      <li
                        v-observe-visibility="visibilityChanged"
                        v-show="allTags.length && allTags.length < totalTags"
                        class="loader"
                      ></li>
                    </template>
                    <template slot="option" slot-scope="option">
                      <span :id="`tag-${option.id}`">
                        {{ option.title }}
                      </span>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      {{ option.title }}
                    </template>
                  </v-select>
                </div>
              </div>
              <!-- </transition> -->
            </div>
          </div>

          <!-- Students Table -->
          <b-skeleton-wrapper :loading="isLoading">
            <template #loading>
              <b-skeleton-table :rows="5" :columns="4" :table-props="{ hover: true }"></b-skeleton-table>
            </template>
            <b-table
              :fields="tableColumns"
              :items="students"
              :busy="isLoading"
              head-variant="light"
              class="table-nowrap"
              hover
              responsive
              no-local-sorting
              selectable
              @row-selected="onRowSelected"
              @sort-changed="onSortChange"
              ref="studentsTable"
            >
              <template #cell(selected)="{ rowSelected }">
                <template v-if="rowSelected">
                  <md-icon>check</md-icon>
                </template>
              </template>
              <template #cell(avatar)="data">
                <user-avatar slot="aside" size="md" :user="data.item.student.user"> </user-avatar>
              </template>

              <template #cell(name)="data"
                ><strong>
                  {{ data.item.student.user.first_name }} {{ data.item.student.user.last_name }}</strong
                ></template
              >
              <template #cell(email)="data"> {{ data.item.student.user.email }}</template>
              <template #cell(start_date)="data">
                {{ get(data.item.student, 'start_date') ? formatDateSimple(get(data.item.student, 'start_date')) : '' }}
              </template>
            </b-table>
          </b-skeleton-wrapper>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <!-- <pagination
              v-model="currentPage"
              :total-rows="totalStudents"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="invites-table"
            /> -->
          </div>
        </div>
      </div>
    </div>
    <security-modal
      :showModal="showSecModal"
      :action="action"
      @close="hideModal"
      @fetch="fetchStudents"
      @generate="fetchGenerateReport"
    />
    <success-password-modal :show-modal="showPassModal" :password="password" @close="hidePassModal" />
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import { debounce, get } from 'lodash';
import { mapGetters, mapActions } from 'vuex';

import Page from '@/components/Page.vue';
//import Pagination from '../../../components/Ui/Pagination.vue';
import MdIcon from '../../../components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE } from '@/common/constants';
import { formatToAPIDate, formatDateSimple } from '@/common/utils';
import DatePicker from 'vue2-datepicker';
import UserAvatar from '@/components/User/UserAvatar.vue';
import SecurityModal from './SecurityModal.vue';
import generator from 'generate-password';
import SuccessPasswordModal from './SuccessPasswordModal.vue';
import Vue from 'vue';
import { ObserveVisibility } from 'vue-observe-visibility';

Vue.directive('observe-visibility', ObserveVisibility);
export default {
  components: { MdIcon, PageHeader, UserAvatar, DatePicker, SecurityModal, SuccessPasswordModal },
  extends: Page,

  name: 'ReportGenPage',

  data() {
    return {
      title: 'Generate Report',

      isLoading: false,
      showFilters: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      types: [],
      typeOptions: [],
      totalStudents: 0,
      students: [],
      selected: [],
      dateRange: null,
      searchTerm: '',
      isReportLoading: false,
      showSecModal: false,
      action: '',
      password: null,
      showPassModal: false,

      windowWidth: window.innerWidth,
      programs: [],
      allPrograms: [],
      areProgramsLoading: false,
      tag_id: null,
      allTags: [],
      areTagsLoading: false,
      limit: 15,
      offset: 0,
      totalTags: 0,
      search: ''
    };
  },

  watch: {
    programs: {
      handler() {
        this.allTags = [];
        this.offset = 0;
        this.tag_id = null;
        this.search = '';
      }
    }
  },

  computed: {
    ...mapGetters('auth', ['getPiiVerificationToken', 'getLoggedInUser']),
    breadcrumb() {
      return [
        { text: 'Home', to: this.routes.home },
        { text: 'Secure Documents', to: { name: 'schl-std-secure-docs' } },
        {
          text: 'Generate Report',
          active: true
        }
      ];
    },
    isTabSmallScreen() {
      return this.windowWidth <= 767;
    },
    tableColumns() {
      return [
        { key: 'selected', label: '', thStyle: { width: '50px', maxWidth: '50px' } },
        { key: 'avatar', label: '' },
        { key: 'name', label: this.$t('studentMsgs.name') },
        { key: 'email', label: this.$t('authMsgs.email') },
        { key: 'start_date', label: 'Start Date' }
      ];
    },

    isMobSmallScreen() {
      return this.windowWidth <= 500;
    }
  },

  methods: {
    ...mapActions('student', ['getUpdatedPiis', 'getGeneratedReport']),
    ...mapActions('school', ['getAllDocs']),
    ...mapActions('attendance', ['getTags']),
    ...mapActions('fileDownload', ['downloadFile']),
    ...mapActions('program', ['getPublicSchoolPrograms']),
    formatDateSimple,

    changeProgram() {
      this.tag_id = null;
      this.fetchTags();
    },
    visibilityChanged(reached) {
      if (reached) {
        this.offset += 15;
        this.fetchTags();
      }
    },
    async fetchTags() {
      this.tag_id = null;
      if (!this.search) this.areTagsLoading = true;
      const response = await this.getTags({
        ...(this.search && { search: this.search }),
        program_ids: this.programs.toString(),
        limit: this.limit,
        offset: this.offset
      });

      this.allTags = this.allTags.concat(response.data.data.results);
      this.totalTags = response.data.data.count;
      this.areTagsLoading = false;

      if (this.allTags.length > 15) {
        setTimeout(() => {
          const el = document.getElementById(`tag-${this.allTags.slice(-15)[0].id}`);

          if (el) {
            el.scrollIntoView({ behavior: 'instant', block: 'nearest' });
          }
        }, 100);
      }
    },

    debouncedSearchTag: debounce(async (loading, search, vm) => {
      vm.search = search;
      const response = await vm.getTags({
        search,
        program__id: vm.programs.toString(),
        limit: vm.limit,
        offset: vm.offset
      });
      loading(false);

      vm.allTags = [];
      vm.totalTags = 0;
      vm.allTags = vm.allTags.concat(response.data.data.results);
      vm.totalTags = response.data.data.count;
    }, 500),

    async fetchOptions(search, loading) {
      if (!this.tag_id) {
        this.offset = 0;
        this.allTags = [];
        this.search = search;
        loading(true);
        this.debouncedSearchTag(loading, search, this);
      }
    },
    get,
    openPassModal() {
      this.showPassModal = true;
    },
    hidePassModal() {
      this.showPassModal = false;
      this.password = '';
    },
    openModal(btnAction) {
      this.showSecModal = true;
      this.action = btnAction;
    },
    async fetchPrograms() {
      this.areProgramsLoading = true;

      const response = await this.getPublicSchoolPrograms({
        school: this.getLoggedInUser.linked_entity.id
      });
      this.allPrograms = response.data;
      this.areProgramsLoading = false;
    },
    clearFilters() {
      this.types = [];
      this.programs = [];
    },
    hideModal() {
      this.showSecModal = false;
      this.action = '';
    },
    dateChange(value) {
      if (value[0]) {
        this.dateRange = value;
      } else {
        this.dateRange = null;
        this.students = [];
      }
    },

    generateReport() {
      if (this.getPiiVerificationToken) {
        this.fetchGenerateReport();
      } else {
        this.openModal('generate');
      }
    },

    async fetchGenerateReport() {
      this.isReportLoading = true;
      try {
        this.password = generator.generate({ length: 10, numbers: true, strict: true });
        const stdIds = this.selected.map(std => std.student.id);
        const res = await this.getGeneratedReport({
          student_ids: stdIds,
          from_date: formatToAPIDate(this.dateRange[0]),
          to_date: formatToAPIDate(this.dateRange[1]),
          password: this.password,
          program_ids: this.programs,
          selected_tag: this.tag_id
        });
        await this.downloadFile({ fileUrl: res.data.file_url, removeTimestamp: true });

        this.openPassModal();
      } catch (err) {
        if (err.response.status === 403) {
          this.openModal('generate');
        } else {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      }

      this.isReportLoading = false;
    },
    onRowSelected(items) {
      this.selected = items;
    },
    onFetchBtnClick() {
      if (this.getPiiVerificationToken) {
        this.fetchStudents();
      } else {
        this.openModal('fetch');
      }
    },

    async fetchStudents() {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();
      try {
        // this.$refs.studentsTable.clearSelected();
        this.students = [];

        const response = await this.getUpdatedPiis({
          types: this.types.map(doc => doc.type),
          program_ids: this.programs,
          selected_tag: this.tag_id,
          from_date: formatToAPIDate(this.dateRange[0]),
          to_date: formatToAPIDate(this.dateRange[1])
        });
        this.students = get(response, 'data', []);

        this.totalStudents = response.data.length;
        this.$nextTick(() => this.$refs.studentsTable.selectAllRows());
      } catch (err) {
        if (err.response.status === 403) {
          this.openModal('fetch');
        } else {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      }
      this.isLoading = false;
    },

    async fetchAllDocs() {
      try {
        const res = await this.getAllDocs();
        this.typeOptions = res.data;
      } catch (error) {
        //
      }
    },

    onPageChange(pageNum) {
      this.fetchStudents(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchStudents();
    },

    onSearch() {
      this.debouncedSearchStudents(this);
    },

    debouncedSearchStudents: debounce(vm => {
      vm.fetchStudents();
    }, 500),

    handleResize() {
      this.windowWidth = window.innerWidth;
    }
  },

  async mounted() {
    window.addEventListener('resize', this.handleResize);
    if (this.$route.params.program) {
      this.showFilters = true;
      this.programs.push(this.$route.params.program);
    }
    if (this.$route.params.dateRange || this.$route.params.tag_id) {
      this.dateRange = get(this.$route.params, 'dateRange');
      this.tag_id = get(this.$route.params, 'tag_id');
      this.fetchStudents();
    }

    this.fetchPrograms();
    this.fetchAllDocs();
    this.fetchTags();
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>

<style scoped>
.slide-enter-active,
.slide-leave-active {
  -moz-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
}
.slide-enter-to,
.slide-leave {
  max-height: 200px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
