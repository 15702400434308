<template>
  <div>
    <page-header
      :title="title"
      :container-class="containerClass"
      infoText="Review enrollment requests from students."
    />
    <div class="page-section">
      <div :class="containerClass">
        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input
                    class="w-lg-auto"
                    :placeholder="'Search Requests'"
                    v-model="searchTerm"
                    @input="onSearch"
                  />
                  <b-btn variant="flush" type="submit">
                    <md-icon v-text="'search'" />
                  </b-btn>
                </form>
              </div>
            </div>
          </div>

          <!--  Table -->
          <b-table
            :fields="tableColumns"
            :items="signUpRequests"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap"
            hover
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
            @row-clicked="showPage"
          >
            <template #cell(avatar)="data">
              <user-avatar
                slot="aside"
                size="md"
                :user="data.item.user"
                first-name-field="first_name"
                last-name-field="last_name"
                :text="getInitials(data.item.full_name)"
              >
              </user-avatar>
            </template>
            <template #cell(full_name)="data">
              {{ data.item.full_name }}
            </template>
            <template #cell(email)="data">
              {{ data.item.email }}
            </template>
            <template #cell(status)="data">
              <b-badge
                :class="` border border-${signUpRequestStatusColors[data.item.status]} text-${
                  signUpRequestStatusColors[data.item.status]
                }`"
                variant="none"
                v-b-popover.hover.top
                :title="$t(`signUpRequestStatusDesc.${data.item.status}`)"
                >{{ $t(`signUpRequestStatusChoice.${data.item.status}`) }}
              </b-badge>
            </template>
            <template #cell(reviewed_by)="data">
              {{ data.item.reviewed_by ? data.item.reviewed_by.first_name : null }}
              {{ data.item.reviewed_by ? data.item.reviewed_by.last_name : null }}
            </template>
            <template #head(actions)="">
              <span></span>
            </template>
            <template #cell(actions)="data">
              <a
                href="#"
                @click.prevent="updateStatus({ id: data.item.id, approvalStatus: SIGN_UP_REQUEST_STATUS.APPROVED })"
                v-b-popover.hover.left
                :title="$t('signUpRequestMsgs.approve')"
                v-if="data.item.status === SIGN_UP_REQUEST_STATUS.PENDING"
              >
                <md-icon class="text-success">check</md-icon>
              </a>
              <a
                href="#"
                @click.prevent="updateStatus({ id: data.item.id, approvalStatus: SIGN_UP_REQUEST_STATUS.REJECTED })"
                v-b-popover.hover.left
                :title="$t('signUpRequestMsgs.reject')"
                v-if="data.item.status === SIGN_UP_REQUEST_STATUS.PENDING"
              >
                <md-icon class="text-danger">clear</md-icon>
              </a>
            </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalSignUpRequests"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="invites-table"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import { debounce, get } from 'lodash';
import { mapActions } from 'vuex';

import Page from '@/components/Page.vue';
import Pagination from '../../../components/Ui/Pagination.vue';
import MdIcon from '../../../components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE, SIGN_UP_REQUEST_STATUS_COLORS, SIGN_UP_REQUEST_STATUS } from '../../../common/constants';
import { formatDateTime } from '../../../common/utils';
import UserAvatar from '../../../components/User/UserAvatar.vue';

export default {
  components: { MdIcon, PageHeader, Pagination, UserAvatar },
  extends: Page,

  name: 'StudentSignupList',

  data() {
    return {
      title: 'Sign-up Requests',

      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      signUpRequestStatusColors: SIGN_UP_REQUEST_STATUS_COLORS,
      totalSignUpRequests: 0,
      signUpRequests: [],

      searchTerm: '',

      SIGN_UP_REQUEST_STATUS,
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: 'Home', to: this.routes.home },
        { text: 'Manage Sign-up Requests', active: true },
      ];
    },

    tableColumns() {
      return [
        { key: 'avatar', label: '', tdClass: 'clickable-item' },
        { key: 'full_name', label: 'Name', tdClass: 'clickable-item' },
        { key: 'email', label: 'Email', tdClass: 'clickable-item' },
        { key: 'status', label: 'Status', tdClass: 'clickable-item' },
        {
          key: 'reviewed_at',
          label: 'Reviewed At',
          formatter: (value) => (value ? formatDateTime(value) : value),
          tdClass: 'clickable-item',
        },
        { key: 'reviewed_by', label: 'Reviewed By', tdClass: 'clickable-item' },
        {
          key: 'created_at',
          label: 'Requested At',
          formatter: (value) => formatDateTime(value),
          tdClass: 'clickable-item',
        },
        {
          key: 'actions',
          tdClass: 'text-right',
          thStyle: { minWidth: '100px' },
        },
      ];
    },
  },

  methods: {
    ...mapActions('signUpRequest', ['getAllSignUpRequests', 'updateSignUpRequestStatus']),
    getInitials(fullName) {
      const arr = fullName.split(' ');
      return get(arr, '0.0', '') + get(arr, '1.0', '');
    },
    showPage(row) {
      this.$router.push({ name: 'show-student-sign-up-request', params: { id: row.id } });
    },
    async updateStatus({ id, approvalStatus = undefined }) {
      this.isStatusUpdating = true;
      try {
        let isConfirmed = true;
        if (approvalStatus === SIGN_UP_REQUEST_STATUS.REJECTED) {
          isConfirmed = await this.$bvModal.msgBoxConfirm(
            'Please confirm that you want to reject the selected account request.',
            {
              title: 'Are you sure?',
              size: 'md',
              buttonSize: 'sm',
              okVariant: 'danger',
              okTitle: 'Yes',
              cancelTitle: 'No',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true,
            }
          );
        }

        if (isConfirmed) {
          const data = {
            status: approvalStatus,
          };
          await this.updateSignUpRequestStatus({ id: id, data });

          if (approvalStatus === SIGN_UP_REQUEST_STATUS.APPROVED) {
            this.makeToast({ variant: 'success', msg: 'Sign Up Request has been approved.' });
          } else if (approvalStatus === SIGN_UP_REQUEST_STATUS.REJECTED) {
            this.makeToast({ variant: 'success', msg: 'Sign Up Request has been rejected.' });
          }
          this.fetchSignUpRequests();
        }
      } catch ($e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isStatusUpdating = false;
    },

    async fetchSignUpRequests(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getAllSignUpRequests({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params,
      });
      this.signUpRequests = response.data.results;
      this.currentPage = pageNum;
      this.totalSignUpRequests = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchSignUpRequests(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchSignUpRequests();
    },

    onSearch() {
      this.debouncedSearchInvites(this);
    },

    debouncedSearchInvites: debounce((vm) => {
      vm.fetchSignUpRequests();
    }, 500),
  },

  async mounted() {
    this.fetchSignUpRequests();
  },
};
</script>
