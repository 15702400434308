<template>
  <div>
    <page-header :title="title" :container-class="containerClass" />
    <div class="page-section">
      <div :class="containerClass">
        <page-separator :title="'Enter Emails to invite'" />
        <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
          <!-- Basic School Info -->
          <b-row v-for="(stdEmail, index) in $v.emails.$each.$iter" :key="index" class="mb-2">
            <b-form-group :label="'Email'" label-for="email" label-class="form-label" class="col-md-10">
              <b-form-input
                id="name"
                :placeholder="'Email'"
                v-model.trim="stdEmail.email.$model"
                :state="(!stdEmail.email.required || !stdEmail.email.email) && stdEmail.$dirty ? false : null"
              />

              <b-form-invalid-feedback v-if="!stdEmail.email.email">
                Invalid email. Please make sure that it's in correct format i.e.
                test@example.com.</b-form-invalid-feedback
              >
              <b-form-invalid-feedback v-else>This field is required.</b-form-invalid-feedback>
            </b-form-group>
            <div class="mt-4 pl-2">
              <a class="btn btn-primary btn-md" href="#" @click.prevent="addEmail(index)">
                <md-icon>add</md-icon>
              </a>
              <a
                href="#"
                class="btn btn-danger ml-2 btn-md"
                @click.prevent="removeEmail(index)"
                v-if="emails.length > 1"
              >
                <md-icon>close</md-icon>
              </a>
            </div>
          </b-row>
          <b-btn variant="primary" style="width: 170px" type="submit" class="btn-normal">
            <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
            <span v-else>Invite Students</span>
          </b-btn>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { get } from 'lodash';
import { required, email } from 'vuelidate/lib/validators';
import Page from '../../../components/Page.vue';
import PageHeader from '@/components/Ui/PageHeader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import { USER_ROLE_TYPES, USER_ROLES, SEND_INVITE_STATUSES } from '../../../common/constants';
export default {
  name: 'PostInvite',
  components: { PageHeader, PageSeparator },
  extends: Page,
  data() {
    return {
      title: 'Invite Students',
      emails: [
        {
          email: '',
          role_type: USER_ROLE_TYPES.STUDENT,
          role: USER_ROLES.STUDENT,
        },
      ],
      isLoading: false,
    };
  },
  computed: {
    breadcrumb() {
      return [
        { text: 'Home', to: this.routes.home },
        { text: 'Manage Invites', to: { name: 'manage-student-invite-list' } },
        {
          text: 'Invite Students',
          active: true,
        },
      ];
    },
  },
  validations() {
    return {
      emails: {
        required,
        $each: {
          email: { required, email },
          role_type: { required },
          role: { required },
        },
      },
    };
  },

  methods: {
    ...mapActions('invite', ['createInvite']),

    addEmail(index) {
      this.emails.splice(index + 1, 0, {
        email: '',
        role_type: 'student',
        role: 'student',
      });
    },

    removeEmail(index) {
      this.emails.splice(index, 1);
    },
    async onSubmit() {
      this.isLoading = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          await this.createInvite({
            invites: this.emails,
          });
          this.makeToast({ variant: 'success', msg: 'Invites sent successfully' });
          setTimeout(() => this.$router.push({ name: 'manage-student-invite-list' }), 250);
        } catch (err) {
          if (get(err.response.data, 'data.invite_status') === SEND_INVITE_STATUSES.NOT_CREATED) {
            this.makeToast({ variant: 'danger', msg: "Couldn't send invites to the given email address(es)" });
          } else {
            this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
          }
        }
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }

      this.isLoading = false;
    },
  },
};
</script>

<style>
</style>
