<template>
  <div :class="containerClass" class="page-section" style="margin-top: -15px">
    <div v-if="isLoading">
      <div class="pl-32pt pb-8pt">
        <div class="d-flex align-items-center mb-2">
          <b-skeleton width="20%"></b-skeleton>
        </div>
        <p class="text-70 mb-lg-32pt">
          <b-skeleton></b-skeleton>
          <b-skeleton width="85%"></b-skeleton>

          <b-skeleton></b-skeleton>
          <b-skeleton width="85%"></b-skeleton>

          <b-skeleton></b-skeleton>
        </p>
      </div>
    </div>
    <div v-else>
      <div class="mb-2">
        <router-link :to="{ name: 'feature-updates-page' }" style="text-decoration: underline">
          <span class="material-icons icon-18pt text-80 ml-4" style="font-size: 40px">navigate_before</span>
        </router-link>
      </div>
      <div class="pl-32pt pb-8pt">
        <div class="d-flex align-items-center">
          <div class="mb-4">
            <h2 class="mb-0">{{ featureUpdate.title }}</h2>
            <span class="mt-0">
              {{ formatDateTime(featureUpdate.created_at) }}
            </span>
          </div>
        </div>

        <p
          v-if="featureUpdate.details"
          style="font-size: 16px"
          class="text-100"
          v-safe-html="featureUpdate.details"
        ></p>
        <p v-else style="font-size: 16px" class="text-100" v-safe-html="featureUpdate.summary"></p>
      </div>
    </div>
  </div>
</template>



<script>
import Page from '@/components/Page.vue';
import { mapActions } from 'vuex';
import { formatDateTime } from '@/common/utils';

export default {
  components: {},
  extends: Page,

  data() {
    return {
      title: 'Feature Update',
      featureUpdate: {},
      isLoading: true,
    };
  },
  watch: {
    featureUpdate: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value) {
          this.title = value.title;
        }
      },
    },
  },
  methods: {
    ...mapActions('featureUpdates', ['getFeatureUpdate', 'updateNotification']),
    ...mapActions('notifications', ['updateNotification']),

    formatDateTime,

    async fetchFeatureUpdates() {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getFeatureUpdate(this.$route.params.id);

      this.featureUpdate = response.data;
      this.isLoading = false;
    },
    async setNotificationRead() {
      const data = { is_read: true };
      await this.updateNotification({ id: this.$route.query.notification, data });
    },
  },
  mounted() {
    this.fetchFeatureUpdates();
    if (this.$route.query.notification) {
      this.setNotificationRead();
    }
  },
};
</script>
