<template>
  <div>
    <page-header :title="title" :container-class="containerClass" infoText="See and manage invitations." />
    <div class="page-section">
      <div :class="containerClass">
        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input
                    class="w-lg-auto"
                    :placeholder="'Search Invites'"
                    v-model="searchTerm"
                    @input="onSearch"
                  />
                  <b-btn variant="flush" type="submit">
                    <md-icon v-text="'search'" />
                  </b-btn>
                </form>
              </div>

              <div class="col-lg d-flex flex-wrap justify-content-end">
                <b-btn variant="light" @click.prevent="resendAll" :disabled="isResendingAll || !totalInvites"
                  ><md-icon class="icon-16pt mr-1">refresh</md-icon>Resend All</b-btn
                >
                <b-btn
                  class="ml-2"
                  :to="{ name: 'manage-student-invites' }"
                  exact
                  variant="primary"
                  v-text="'Invite Students'"
                />
              </div>
            </div>
          </div>

          <!-- Schools Table -->
          <b-table
            :fields="tableColumns"
            :items="invites"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap"
            hover
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
          >
            <template #cell(email)="data">
              {{ data.item.email }}
            </template>

            <template #cell(status)="data">
              <b-badge
                :class="` border border-${inviteStatusColors[data.item.status]} text-${
                  inviteStatusColors[data.item.status]
                }`"
                variant="none"
                v-b-popover.hover.top
                :title="$t(`inviteStatusDesc.${data.item.status}`)"
                >{{ $t(`inviteStatusChoice.${data.item.status}`) }}
              </b-badge>
            </template>
            <template #cell(created_by)="data">
              {{ data.item.created_by.first_name }} {{ data.item.created_by.last_name }}
            </template>

            <template #head(actions)="">
              <span></span>
            </template>
            <template #cell(actions)="data">
              <a
                href="#"
                @click.prevent="copyUrl(data.item)"
                v-b-popover.hover.left
                title="Copy Invite Link"
                v-if="data.item.status === INVITE_STATUSES.PENDING"
              >
                <md-icon>content_copy</md-icon>
              </a>
              <a
                href="#"
                @click.prevent="resend(data.item)"
                v-b-popover.hover.left
                :title="$t('inviteMsgs.resend')"
                v-if="data.item.status === INVITE_STATUSES.PENDING || data.item.status === INVITE_STATUSES.EXPIRED"
              >
                <md-icon>refresh</md-icon>
              </a>
              <a
                href="#"
                @click.prevent="revokeInvite(data.item.id)"
                v-b-popover.hover.left
                :title="$t('inviteMsgs.revoke')"
                v-if="data.item.status === INVITE_STATUSES.PENDING || data.item.status === INVITE_STATUSES.EXPIRED"
              >
                <md-icon class="text-danger">delete</md-icon>
              </a>
            </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalInvites"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="invites-table"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import { debounce } from 'lodash';
import { mapActions } from 'vuex';

import Page from '@/components/Page.vue';
import Pagination from '../../../components/Ui/Pagination.vue';
import MdIcon from '../../../components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE, INVITE_STATUS_COLORS, INVITE_STATUSES } from '../../../common/constants';
import { formatDateTime } from '../../../common/utils';

export default {
  components: { MdIcon, PageHeader, Pagination },
  extends: Page,

  name: 'InviteList',

  data() {
    return {
      title: 'Manage Invites',

      isLoading: false,
      isResendingAll: false,

      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,

      inviteStatusColors: INVITE_STATUS_COLORS,
      totalInvites: 0,
      invites: [],

      searchTerm: '',

      isCopied: false,
      INVITE_STATUSES,
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: 'Home', to: this.routes.home },
        { text: 'Manage Invites', active: true },
      ];
    },

    tableColumns() {
      return [
        { key: 'email', label: 'Email' },
        { key: 'expires_at', label: 'Expiry', formatter: (value) => (value ? formatDateTime(value) : value) },
        { key: 'status', label: 'Status' },
        { key: 'created_by', label: 'Invited By' },
        { key: 'updated_at', label: 'Sent At', formatter: (value) => formatDateTime(value) },
        {
          key: 'actions',
          tdClass: 'text-right',
          thStyle: { minWidth: '100px' },
        },
      ];
    },
  },

  methods: {
    ...mapActions('invite', ['getAllInvites', 'deleteInvite', 'resendInvite', 'resendAllInvites']),
    async resendAll() {
      this.isResendingAll = true;
      if (this.totalInvites) {
        try {
          await this.resendAllInvites();
          this.fetchInvites();
          this.makeToast({ variant: 'success', msg: 'Invite Resent.' });
        } catch (err) {
          if (err.response.status === 400) {
            this.makeToast({ variant: 'danger', msg: 'No invites to resend' });
          } else {
            this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
          }
        }
      } else {
        this.makeToast({ variant: 'danger', msg: 'No invites to resend' });
      }
      this.isResendingAll = false;
    },
    async copyUrl(data) {
      try {
        const urlLink = `${window.location.origin}/accept-invite?token=${data.token}`;
        await navigator.clipboard.writeText(urlLink);
        this.isCopied = true;
        setTimeout(() => (this.isCopied = false), 2000);

        this.makeToast({ variant: 'success', msg: 'Invite link copied.' });
      } catch ($e) {
        this.makeToast({ variant: 'danger', msg: 'Unable to copy.' });
      }
    },
    async resend(data) {
      try {
        await this.resendInvite(data.id);
        this.fetchInvites();

        this.makeToast({ variant: 'success', msg: 'Invite Resent.' });
      } catch ($e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },

    async revokeInvite(id) {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          'Please confirm that you want to revoke the selected invite.',
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (isConfirmed) {
          await this.deleteInvite(id);
          this.fetchInvites();
          this.makeToast({ variant: 'success', msg: 'Invite Successfully Revoked' });
        }
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },
    async fetchInvites(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getAllInvites({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params,
      });
      this.invites = response.data.results;
      this.currentPage = pageNum;
      this.totalInvites = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchInvites(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchInvites();
    },

    onSearch() {
      this.debouncedSearchInvites(this);
    },

    debouncedSearchInvites: debounce((vm) => {
      vm.fetchInvites();
    }, 500),
  },

  async mounted() {
    this.fetchInvites();
  },
};
</script>
