<template>
  <div class="container">
    <div v-if="isLoading" class="row">
      <div class="col-xl-4 d-flex flex-column align-items-center text-center" style="margin-top: 25px">
        <div class="d-flex flex-column align-items-center text-center" style="margin-top: 80px">
          <div style="position: absolute; margin-top: -75px; z-index: 1">
            <b-skeleton size="140px" type="avatar"></b-skeleton>
          </div>
          <div
            class="card text-center p-3"
            style="width: 100%; position: relative; color: white !important; border-radius: 1.5rem"
          >
            <div class="d-flex flex-column align-items-center mt-3">
              <b-skeleton class="mt-5" animation="fade" width="80px"></b-skeleton>

              <b-skeleton class="mt-4" animation="fade" width="270px"></b-skeleton>
              <b-skeleton class="mt-2" animation="fade" width="55%"></b-skeleton>
              <b-skeleton class="mt-2" animation="fade" width="70%"></b-skeleton>
              <b-skeleton class="mt-2" animation="fade" width="70%"></b-skeleton>
            </div>
          </div>
        </div>
      </div>
      <div
        :class="isMobileScreen ? 'text-center' : ''"
        class="col-xl-8 px-2"
        :style="isMobileScreen ? 'margin-top: 12px' : 'margin-top: 15px'"
      >
        <div class="d-flex align-items-center justify-content-center">
          <div :class="{ 'd-flex flex-column align-items-center': isMobileScreen }">
            <b-skeleton v-if="isMobileScreen" width="300px" class="mb-2" type="button"></b-skeleton>
            <b-skeleton animation="fade" width="250px"></b-skeleton>
          </div>
          <div class="ml-auto" v-if="!isMobileScreen">
            <b-skeleton width="140px" type="button"></b-skeleton>
          </div>
        </div>
        <div class="card my-2">
          <b-card-header>
            <div class="d-flex align-items-center" :class="isMobileScreen ? 'justify-content-center' : ''">
              <b-skeleton animation="fade" width="35%"></b-skeleton>
            </div>
          </b-card-header>
          <div class="py-2 px-3" :class="{ 'd-flex flex-column align-items-center': isMobileScreen }">
            <b-skeleton animation="fade" width="85%"></b-skeleton>
            <b-skeleton animation="fade" width="55%"></b-skeleton>
            <b-skeleton class="mb-4" animation="fade" width="70%"></b-skeleton>

            <b-skeleton animation="fade" width="85%"></b-skeleton>
            <b-skeleton animation="fade" width="55%"></b-skeleton>
            <b-skeleton animation="fade" width="70%"></b-skeleton>
          </div>
        </div>
        <div class="card">
          <b-card-header>
            <div class="d-flex align-items-center" :class="isMobileScreen ? 'justify-content-center' : ''">
              <b-skeleton animation="fade" width="35%"></b-skeleton>
            </div>
          </b-card-header>
          <div class="py-2 px-3" :class="{ 'd-flex flex-column align-items-center': isMobileScreen }">
            <b-skeleton animation="fade" width="85%"></b-skeleton>
            <b-skeleton animation="fade" width="55%"></b-skeleton>
            <b-skeleton class="mb-4" animation="fade" width="70%"></b-skeleton>

            <b-skeleton animation="fade" width="85%"></b-skeleton>
            <b-skeleton animation="fade" width="55%"></b-skeleton>
            <b-skeleton animation="fade" width="70%"></b-skeleton>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <p class="backResult text-50">
        <router-link :to="{ name: 'spotlight-list' }" style="text-decoration: none">
          <span class="material-icons icon-18pt text-80">navigate_before</span>
        </router-link>
      </p>
      <div class="row">
        <div class="col-xl-4 d-flex flex-column align-items-center text-center" style="margin-top: 25px">
          <div class="d-flex flex-column align-items-center text-center" style="margin-top: 80px">
            <div style="position: absolute; margin-top: -75px; z-index: 1">
              <b-avatar
                style="border: 5px solid whitesmoke"
                class="mb-3"
                :src="get(story, 'image_url')"
                variant="light"
                size="150"
              ></b-avatar>
            </div>
            <div
              class="card text-center p-3 bg-dark"
              style="color: white !important; border-radius: 1.5rem; width: 300px"
            >
              <h4 class="mb-2" style="padding-top: 65px; color: white !important">
                {{ get(story, 'first_name') }} {{ get(story, 'last_name') }}
              </h4>
              <div class="mb-2" v-if="getLoggedInUser.role_type === USER_ROLE_TYPES.SUPER_ADMIN">
                <i class="fas fa-landmark icon-16pt"></i>
                {{ get(story, 'school.name') }}
              </div>
              <div class="mb-2">
                <i class="fas fa-graduation-cap icon-16pt"></i>
                {{ $t(`StudentType.${get(story, 'student_type')}`) }}
              </div>
              <div class="mb-2">
                <i class="fas fa-map-marker-alt icon-16pt"></i>
                {{ get(story, 'city') }}, {{ get(story, 'state') }}
              </div>
              <div>
                <i class="fas fa-clock icon-16pt"></i>
                {{ formatFullDate(get(story, 'created_at')) }}
              </div>

              <div class="d-flex align-items-center my-3">
                <hr style="background-color: white; margin-left: 40px; width: 30%; height: 2px" />
                <md-icon style="font-size: 17px">local_library</md-icon>
                <hr style="background-color: white; margin-right: 40px; width: 30%; height: 2px" />
              </div>

              <div>
                <b-button
                  v-for="program in get(story, 'programs')"
                  :key="program.id"
                  class="mb-2 mr-2"
                  pill
                  style="color: white"
                  variant="outline-white"
                  size="sm"
                  disabled
                >
                  {{ program.title }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="isMobileScreen ? 'text-center' : ''"
          class="col-xl-8 px-2"
          :style="isMobileScreen ? 'margin-top: 12px' : 'margin-top: 15px'"
        >
          <div class="d-flex align-items-center justify-content-center">
            <div>
              <b-skeleton
                v-if="isMobileScreen && isStatusLoading && getLoggedInUser.role_type !== USER_ROLE_TYPES.SUPER_ADMIN"
                width="300px"
                type="button"
              ></b-skeleton>
              <div v-else>
                <b-dropdown
                  v-if="
                    isMobileScreen &&
                    (getLoggedInUser.role_type === USER_ROLE_TYPES.SUPER_ADMIN ||
                      (get(getLoggedInUser, 'role_type') === USER_ROLE_TYPES.SCHOOL && !get(status, 'student_id')) ||
                      (get(status, 'school_partnered') && !get(status, 'student_id')))
                  "
                  text="Contact Candidate"
                  variant="secondary"
                  class="btn-width mb-4"
                >
                  <b-dropdown-item :href="`mailto:${get(status, 'email') || get(story, 'email')}`"
                    ><i class="material-icons mr-1">email</i>Email</b-dropdown-item
                  >
                  <b-dropdown-item :href="`tel:${get(status, 'phone') || get(story, 'phone')}`"
                    ><i class="material-icons mr-1">phone</i>Call</b-dropdown-item
                  >
                </b-dropdown>
                <b-btn
                  variant="secondary"
                  class="btn-width mb-4"
                  style="width: 100%"
                  v-if="
                    isMobileScreen &&
                    ((get(getLoggedInUser, 'role_type') === USER_ROLE_TYPES.SCHOOL && get(status, 'student_id')) ||
                      (get(status, 'school_partnered') && status.student_id))
                  "
                  :to="
                    get(getLoggedInUser, 'role_type') === USER_ROLE_TYPES.EMPLOYER
                      ? `/employer/candidates/${get(status, 'student_id')}`
                      : get(getLoggedInUser, 'role_type') === USER_ROLE_TYPES.ORGANIZATION
                      ? `/organization/candidates/${get(status, 'student_id')}`
                      : `/school/students/${get(status, 'student_id')}`
                  "
                >
                  <span> Show Profile</span>
                </b-btn>
                <b-btn
                  v-if="
                    isMobileScreen &&
                    !(get(getLoggedInUser, 'role_type') === USER_ROLE_TYPES.SCHOOL) &&
                    !(getLoggedInUser.role_type === USER_ROLE_TYPES.SUPER_ADMIN) &&
                    !get(status, 'school_partnered') &&
                    !get(status, 'student_id')
                  "
                  text="Contact Candidate"
                  variant="grey"
                  class="btn-width mb-4"
                  style="background-color: grey; color: white"
                  @click="openContactModal"
                >
                  <span><i class="material-icons icon-16pt mr-1">lock_open</i> Contact Candidate</span>
                </b-btn>
              </div>

              <div class="d-flex align-items-center">
                <strong style="font-size: 32px; font-weight: 800"></strong>
              </div>
            </div>
            <div class="ml-auto" v-if="!isMobileScreen">
              <b-skeleton
                v-if="isStatusLoading && getLoggedInUser.role_type !== USER_ROLE_TYPES.SUPER_ADMIN"
                width="140px"
                type="button"
              ></b-skeleton>
              <div v-else>
                <b-dropdown
                  text="Contact Candidate"
                  variant="secondary"
                  class="btn-width"
                  style="width: 100%"
                  v-if="
                    getLoggedInUser.role_type === USER_ROLE_TYPES.SUPER_ADMIN ||
                    (get(getLoggedInUser, 'role_type') === USER_ROLE_TYPES.SCHOOL && !get(status, 'student_id')) ||
                    (get(status, 'school_partnered') && !get(status, 'student_id'))
                  "
                >
                  <b-dropdown-item :href="`mailto:${get(status, 'email') || get(story, 'email')}`"
                    ><i class="material-icons mr-1">email</i>Email</b-dropdown-item
                  >
                  <b-dropdown-item :href="`tel:${get(status, 'phone') || get(story, 'phone')}`"
                    ><i class="material-icons mr-1">phone</i>Call</b-dropdown-item
                  >
                </b-dropdown>

                <b-btn
                  variant="secondary"
                  class="btn-width"
                  style="width: 100%"
                  v-if="
                    (get(getLoggedInUser, 'role_type') === USER_ROLE_TYPES.SCHOOL && get(status, 'student_id')) ||
                    (get(status, 'school_partnered') && status.student_id)
                  "
                  :to="
                    get(getLoggedInUser, 'role_type') === USER_ROLE_TYPES.EMPLOYER
                      ? `/employer/candidates/${get(status, 'student_id')}`
                      : get(getLoggedInUser, 'role_type') === USER_ROLE_TYPES.ORGANIZATION
                      ? `/organization/candidates/${get(status, 'student_id')}`
                      : `/school/students/${get(status, 'student_id')}`
                  "
                >
                  <span> Show Profile</span>
                </b-btn>
                <b-btn
                  class="btn-width"
                  variant="grey"
                  style="width: 100%; background-color: grey; color: white"
                  v-if="
                    !(get(getLoggedInUser, 'role_type') === USER_ROLE_TYPES.SCHOOL) &&
                    !(getLoggedInUser.role_type === USER_ROLE_TYPES.SUPER_ADMIN) &&
                    !get(status, 'school_partnered') &&
                    !get(status, 'student_id')
                  "
                  @click="openContactModal"
                >
                  <span><i class="material-icons icon-16pt mr-1">lock_open</i> Contact Candidate</span>
                </b-btn>
                <!-- </span> -->
              </div>
            </div>
          </div>

          <div class="card my-2" :key="`story-c1-${get(story, 'id', '')}`">
            <b-card-header>
              <div class="d-flex align-items-center" :class="isMobileScreen ? 'justify-content-center' : ''">
                <md-icon class="mr-1">assignment</md-icon>
                <strong style="font-size: 20px">Future Goals</strong>
              </div>
            </b-card-header>
            <div class="py-2 px-3" v-safe-html="get(story, 'future_goals', '')"></div>
          </div>
          <div class="card" :key="`story-c2-${get(story, 'id', '')}`">
            <b-card-header>
              <div class="d-flex align-items-center" :class="isMobileScreen ? 'justify-content-center' : ''">
                <md-icon class="mr-1">assignment</md-icon>
                <strong style="font-size: 20px">Personal Story</strong>
              </div>
            </b-card-header>
            <div class="py-2 px-3" v-safe-html="get(story, 'personal_story', '')"></div>
          </div>
        </div>
      </div>
    </div>
    <contact-modal :show-modal="showContactModal" :school="get(story, 'school')" @close="hideContactModal" />
  </div>
</template>

<script>
import { get, isEmpty } from 'lodash';
import Page from '@/components/Page.vue';
import { mapActions, mapGetters } from 'vuex';
import { formatFullDate } from '@/common/utils';
import { USER_ROLE_TYPES } from '@/common/constants';
import ContactModal from './ContactModal.vue';
export default {
  components: { ContactModal },
  extends: Page,
  data() {
    return {
      USER_ROLE_TYPES,
      title: 'Spotlight',
      story: null,
      isLoading: true,
      status: null,
      isStatusLoading: true,
      windowWidth: window.innerWidth,
      showContactModal: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    isMobileScreen() {
      return this.windowWidth <= 1200;
    },
  },
  methods: {
    ...mapActions('stories', [
      'getStdStory',
      'getEmpSclPartnership',
      'getOrgSclPartnership',
      'getContact',
      'getSclEnrolledStudent',
    ]),
    get,
    formatFullDate,
    isEmpty,
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    openContactModal() {
      this.showContactModal = true;
    },
    hideContactModal() {
      this.showContactModal = false;
    },

    async fetchPartnershipStatus() {
      this.isStatusLoading = true;
      try {
        const res =
          this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER
            ? await this.getEmpSclPartnership(this.story.id)
            : this.getLoggedInUser.role_type === USER_ROLE_TYPES.ORGANIZATION
            ? await this.getOrgSclPartnership(this.story.id)
            : await this.getSclEnrolledStudent(this.story.id);
        this.status = res.data;
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isStatusLoading = false;
    },

    async fetchStory() {
      this.isLoading = true;
      try {
        const res = await this.getStdStory(this.$route.params.id);
        this.story = res.data;
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },
  },
  async mounted() {
    window.addEventListener('resize', this.handleResize);
    if (this.$route.params.id) {
      await this.fetchStory();
      if (this.getLoggedInUser.role_type !== USER_ROLE_TYPES.SUPER_ADMIN) {
        await this.fetchPartnershipStatus();
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style scoped lang="scss">
.btn-width ::v-deep .dropdown-menu {
  width: 100% !important;
}
</style>
