<template>
  <b-modal ref="addPiiModal" hide-footer title="Upload Document" centered lazy @hide="hideModal" size="lg">
    <b-form @submit.prevent="onSubmit">
      <b-form-group label="Type" label-for="docType" label-class="form-label">
        <v-select
          id="docType"
          class="form-control v-select-custom"
          label="title"
          v-model="docType"
          :reduce="(item) => item.type"
          placeholder="Select document type"
          :options="piiConfigs"
          :class="!$v.docType.required && $v.docType.$dirty ? 'form-control is-invalid' : ''"
          :clearable="false"
          @input="getQuestions"
        >
          <!-- <template slot="option" slot-scope="option">
            {{ $t(`piiTypes.${option.type}`) }}
          </template> -->
          <template slot="selected-option" slot-scope="option">
            <!-- {{ $t(`piiTypes.${option.type}`) }} -->
            {{ option.title }}
          </template>
        </v-select>
        <b-form-invalid-feedback :state="!$v.docType.required && $v.docType.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <b-form-group label="Document" label-for="document" label-class="form-label" class="row-align-items-center">
        <!-- Upload progress while uploading -->
        <b-progress :max="100" animated v-if="isUploading">
          <b-progress-bar :value="uploadPercent">
            <span>
              Uploading (<strong>{{ uploadPercent }} %</strong>)
            </span>
          </b-progress-bar>
        </b-progress>
        <span v-else-if="docUrl">
          <div class="row">
            <div class="col-md-10">
              <a
                href="#"
                @click.prevent="
                  downloadFile({
                    fileUrl: docUrl,
                    removeTimestamp: true,
                  })
                "
                v-b-popover.hover.top="'Download'"
                ><i class="fas fa-file text-primary"></i> {{ parseFileNameFromUrl(docUrl, true) }}
              </a>
            </div>
            <div class="text-right col-md-2">
              <a href="#" class="text-danger" @click.prevent="removeFile(docType, docType)"
                ><i class="fas fa-times-circle"></i> Remove
              </a>
            </div>
          </div>
        </span>
        <b-media class="align-items-center" vertical-align="center" v-else>
          <b-form-file
            :id="docType"
            placeholder="Select Document (max size: 10MB, only .pdf and image files allowed)"
            :browse-text="$t('generalMsgs.browse')"
            v-model="selectedUploadFile"
            @input="uploadFile()"
            :disabled="isUploading"
            accept=".pdf, image/*"
            required
          />
        </b-media>
      </b-form-group>

      <b-form-group v-if="isDocDateRequired" label="Expiration Date" label-for="expireDate" label-class="form-label">
        <date-picker
          v-model="expireDate"
          type="date"
          format="MM/DD/YYYY"
          value-type="YYYY-MM-DD"
          style="width: 100%"
          lang="en"
          placeholder="MM/DD/YYYY"
          :id="docType"
          :input-attr="{ required: isDocDateRequired }"
        ></date-picker>
      </b-form-group>
      <!-- :input-attr="docStatus === 'required' ? { required: true } : { required: false }" -->

      <div v-if="questions.length">
        <div v-for="(question, index) in questions" :key="index">
          <b-form-group :label="`${question.question}`" label-for="question" label-class="form-label">
            <b-form-textarea id="question" :rows="3" v-model="questions[index].answer" />
          </b-form-group>
        </div>
      </div>

      <div class="d-flex justify-content-end mt-2">
        <b-btn
          variant="primary"
          style="width: 100px"
          type="submit"
          class="btn-normal"
          :disabled="isUploading || isLoading"
        >
          <md-icon class="mr-1">file_upload</md-icon>Upload
        </b-btn>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';
import { get } from 'lodash';
import { required } from 'vuelidate/lib/validators';
import DatePicker from 'vue2-datepicker';
import { parseFileNameFromUrl } from '../../../common/utils';
import { EXPIRATION_REQ_DOCS } from '../../../common/constants';

export default {
  name: 'AddPiiModal',
  props: {
    showModal: { type: Boolean, default: false },
    piiConfigs: { type: Array, default: () => [] },
    userId: { type: Number, default: null },
  },
  components: { DatePicker },
  data() {
    return {
      isLoading: false,
      expireDate: null,
      docUrl: '',
      isUploading: false,
      selectedUploadFile: null,
      uploadPercent: 0,
      uploadCancelTokenSource: null,
      docType: null,
      EXPIRATION_REQ_DOCS,
      questions: [],
    };
  },

  computed: {
    docStatus() {
      return get(
        this.piiConfigs.filter((doc) => doc.type == this.docType),
        '0.status'
      );
    },
    isDocDateRequired() {
      return get(
        this.piiConfigs.find((doc) => doc.type == this.docType),
        'req_expiry_date'
      );
    },
  },
  validations() {
    return {
      docType: { required },
    };
  },
  watch: {
    showModal(value) {
      if (value) {
        this.resetData();

        this.$refs.addPiiModal.show();
      }
    },
  },

  methods: {
    ...mapActions('fileDownload', ['downloadFile']),
    ...mapActions('student', ['addStudentPii', 'getStudentUploadPresignedUrl']),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    parseFileNameFromUrl,
    resetData() {
      this.docType = null;
      this.expireDate = null;
      this.docUrl = '';
      this.isUploading = false;
      this.selectedUploadFile = null;
      this.uploadPercent = 0;
      this.questions = [];
      this.uploadCancelTokenSource = null;
    },
    getQuestions() {
      const doc = this.piiConfigs.filter((doc) => doc.type == this.docType)[0];

      doc.custom_questions.length
        ? doc.custom_questions.map((question) => this.questions.push({ question: question, answer: '' }))
        : (this.questions = []);
    },
    async onSubmit() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        try {
          const data = {
            type: this.docType,
            document_url: this.docUrl,
            student: this.userId,
            expire_at: this.expireDate,
            questions_answers: this.questions,
          };

          await this.addStudentPii(data);
        } catch (error) {
          //  Use if necessary
        }
        this.$refs.addPiiModal.hide();
      }
    },
    async uploadFile(uploadType = 'documents') {
      this.uploadCancelTokenSource = axios.CancelToken.source();
      this.isUploading = true;

      try {
        const urlResp = await this.getStudentUploadPresignedUrl({
          file_name: this.selectedUploadFile.name,
          content_type: this.selectedUploadFile.type,
          upload_type: uploadType,
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.selectedUploadFile,
          config: {
            onUploadProgress: function (progressEvent) {
              this.uploadPercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource.token,
          },
        });
        this.docUrl = urlResp.upload_url.split('?')[0];
      } catch (error) {
        this.selectedUploadFile = null;
      }

      this.uploadCancelTokenSource = null;
      this.uploadPercent = 0;

      this.isUploading = false;
    },
    removeFile() {
      this.selectedUploadFile = null;
      this.docUrl = null;
    },
    hideModal() {
      this.$emit('close');
      this.resetData();
      this.$refs.addPiiModal.hide();
    },

    hideModalManual() {
      this.$refs.addPiiModal.hide();
      this.hideModal();
    },
  },

  async mounted() {},
};
</script>
