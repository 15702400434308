<template>
  <b-modal
    ref="successPasswordModal"
    hide-footer
    title="Successfully Downloaded!"
    centered
    lazy
    scrollable
    @hide="hideModal"
    size="md"
    no-close-on-backdrop
  >
    <template slot="modal-header"> <h5 class="my-0">Successfully Downloaded!</h5></template>
    <div class="d-flex justify-content-center">
      <i class="fas fa-check-circle fa-4x text-success mt-2 mb-2"></i>
    </div>
    <div class="d-flex justify-content-center" style="height: 2rem">
      <h3>File downloaded successfully!</h3>
    </div>
    <div class="d-flex justify-content-center my-2">
      <span>
        Student report has been successfully generated. Use the password
        <a href="#" class="text-primary" @click.prevent="copyPass" v-b-popover.hover.top="'Click to copy'">{{
          password
        }}</a>
        to open the downloaded file.
      </span>
    </div>
    <div class="text-center">
      <b-btn @click.prevent="copyAndClose"> Copy and Close </b-btn>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'SuccessPasswordModal',
  props: { password: { type: String, default: '' }, showModal: { type: Boolean, default: false } },
  data() {
    return {
      isPassCopied: false,
    };
  },
  watch: {
    showModal(value) {
      if (value) {
        this.$refs.successPasswordModal.show();
      }
    },
  },
  methods: {
    async copyPass() {
      try {
        await navigator.clipboard.writeText(this.password);
        this.isPassCopied = true;
        setTimeout(() => (this.isPassCopied = false), 2000);
        this.makeToast({ variant: 'success', msg: 'Password Copied Successfully.' });
      } catch ($e) {
        this.makeToast({ variant: 'danger', msg: 'Unable to copy.' });
      }
    },
    hideModal() {
      this.$emit('close');
      this.$refs.successPasswordModal.hide();
    },

    copyAndClose() {
      this.copyPass();
      this.hideModal();
    },

    hideModalManual() {
      this.hideModal();
    },
  },
};
</script>

